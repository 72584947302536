sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.util=sv.i18n.portlet.social.util || {};
sv.i18n.portlet.social.util.addgrouputil=sv.i18n.portlet.social.util.addgrouputil || {};
sv.i18n.portlet.social.util.addgrouputil.de={
   "name": "Name",
   "nameHelp": "Gruppenname",
   "createGroup": "Gruppe erstellen"
};
sv.i18n.portlet.social.util.addgrouputil.en={
   "object_already_exists": "A group with the same name already exists!",
   "name": "Name",
   "typeHelp": "Open - anyone can join<br/>Moderated - open but only administrator can add members<br/>Closed - only members can see the group",
   "invalid_object_name": "Invalid group name",
   "nameHelp": "Group name",
   "descriptionHelp": "Group description",
   "templateHelp": "Select group type",
   "createGroupError": "Error",
   "createGroupLabel": "Create new group",
   "open": "Open",
   "closed": "Closed",
   "groupAlreadyExists": "A group with the same name already exists!",
   "type": "Status",
   "createGroup": "Create group",
   "description": "Description",
   "template": "Group type",
   "member_moderated": "Moderated"
};
sv.i18n.portlet.social.util.addgrouputil.fi={
   "object_already_exists": "Samanniminen ryhmä on jo olemassa!",
   "name": "Nimi",
   "typeHelp": "Avoin - kaikki voivat päästä jäseneksi<br/>Moderoitu - vain moderaattori voi hyväksyä jäseneksi<br/>Suljettu - vain jäsenet näkevät ryhmän",
   "invalid_object_name": "Virheellinen ryhmän nimi",
   "nameHelp": "Ryhmän nimi",
   "descriptionHelp": "Ryhmän kuvaus",
   "templateHelp": "Valitse ryhmän tyyppi",
   "createGroupError": "Virhe",
   "createGroupLabel": "Luo uusi ryhmä",
   "open": "Avoin",
   "closed": "Suljettu",
   "groupAlreadyExists": "Samanniminen ryhmä on jo olemassa!",
   "type": "Status",
   "createGroup": "Luo ryhmä",
   "description": "Kuvaus",
   "template": "Tyyppi",
   "member_moderated": "Moderoitu"
};
sv.i18n.portlet.social.util.addgrouputil.fr={
   "name": "Nom",
   "nameHelp": "Nom du groupe",
   "createGroup": "Créer un groupe"
};
sv.i18n.portlet.social.util.addgrouputil.nl={
   "name": "Naam",
   "nameHelp": "Groepsnaam",
   "createGroup": "Maak een groep"
};
sv.i18n.portlet.social.util.addgrouputil.no={
   "object_already_exists": "En gruppe med samme navn finnes allerede!",
   "name": "Navn",
   "typeHelp": "Åpen – alle kan bli medlem<br/>Moderert – bare administratorer kan legge til medlemmer<br/>Lukket – bare medlemmer kan se gruppen",
   "invalid_object_name": "Feil gruppenavn",
   "nameHelp": "Gruppens navn",
   "descriptionHelp": "Gruppens beskrivelse",
   "templateHelp": "Velg gruppetype",
   "createGroupError": "Feil",
   "createGroupLabel": "Opprett ny gruppe",
   "open": "Åpen",
   "closed": "Lukket",
   "groupAlreadyExists": "En gruppe med samme navn finnes allerede!",
   "type": "Status",
   "createGroup": "Opprett gruppe",
   "description": "Beskrivelse",
   "template": "Type",
   "member_moderated": "Moderert"
};
sv.i18n.portlet.social.util.addgrouputil.sv={
   "object_already_exists": "En grupp med samma namn finns redan!",
   "name": "Namn",
   "typeHelp": "Öppen - alla kan bli medlemmar<br/>Modererad - endast administratörer kan lägga till medlemmar<br/>Sluten - endast medlemmar kan se gruppen",
   "invalid_object_name": "Felaktigt gruppnamn",
   "nameHelp": "Gruppens namn",
   "descriptionHelp": "Gruppens beskrivning",
   "templateHelp": "Välj grupptyp",
   "createGroupError": "Fel",
   "createGroupLabel": "Skapa ny grupp",
   "open": "Öppen",
   "closed": "Sluten",
   "groupAlreadyExists": "En grupp med samma namn finns redan!",
   "type": "Status",
   "createGroup": "Skapa grupp",
   "description": "Beskrivning",
   "template": "Typ",
   "member_moderated": "Modererad"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.bookmarklist=sv.i18n.portlet.social.bookmarklist || {};
sv.i18n.portlet.social.bookmarklist.bookmarklist=sv.i18n.portlet.social.bookmarklist.bookmarklist || {};
sv.i18n.portlet.social.bookmarklist.bookmarklist.de={};
sv.i18n.portlet.social.bookmarklist.bookmarklist.en={
   "deleteMsg": "Are you sure you want to delete this bookmark?",
   "name": "Name",
   "deleteBookmark": "Delete bookmark?",
   "description": "Description",
   "deleteTitle": "Delete bookmark"
};
sv.i18n.portlet.social.bookmarklist.bookmarklist.fi={};
sv.i18n.portlet.social.bookmarklist.bookmarklist.no={
   "deleteMsg": "Er du sikker på at du vil fjerne aktuelt bokmerke?",
   "name": "Navn",
   "deleteBookmark": "Vil du fjerne bokmerke?",
   "description": "Beskrivelse",
   "deleteTitle": "Fjern"
};
sv.i18n.portlet.social.bookmarklist.bookmarklist.sv={
   "deleteMsg": "Är du säker på att du vill ta bort aktuellt bokmärke?",
   "name": "Namn",
   "deleteBookmark": "Ta bort bookmärke?",
   "description": "Beskrivning",
   "deleteTitle": "Ta bort"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.fileview=sv.i18n.portlet.social.fileview || {};
sv.i18n.portlet.social.fileview.browse=sv.i18n.portlet.social.fileview.browse || {};
sv.i18n.portlet.social.fileview.browse.de={};
sv.i18n.portlet.social.fileview.browse.en={
   "renameFolderTitle": "Rename folder",
   "renameFolderMessage": "New name",
   "createFolderMessage": "Name",
   "moveNodeSingleMessage": "Are you sure you want to move '{0}' to the current folder?",
   "deleteNodeMultiMessage": "Are you sure you want to delete {0} objects?",
   "moveNodeMultiMessage": "Are you sure you want to move {0} objects to the current folder?",
   "deleteNodeTitle": "Delete",
   "moveNodeTitle": "Move",
   "deleteNodeSingleMessage": "Are you sure you want to delete '{0}'?",
   "createFolderTitle": "Create folder"
};
sv.i18n.portlet.social.fileview.browse.fi={
   "renameFolderTitle": "Vaihda kansion nimi",
   "renameFolderMessage": "Uusi nimi",
   "createFolderMessage": "Nimi",
   "moveNodeSingleMessage": "Oletko varma, että haluat siirtää '{0}' tähän kansioon?",
   "deleteNodeMultiMessage": "Oletko varma, että haluat poistaa {0} kohteen?",
   "moveNodeMultiMessage": "Oletko varma, että haluat siirtää {0} kohteen tähän kansioon?",
   "deleteNodeTitle": "Poista",
   "moveNodeTitle": "Siirtää",
   "deleteNodeSingleMessage": "Oletko varma, että haluat poistaa '{0}'?",
   "createFolderTitle": "Luo kansio"
};
sv.i18n.portlet.social.fileview.browse.no={
   "renameFolderTitle": "Bytt navn på mappe",
   "renameFolderMessage": "Nytt navn",
   "createFolderMessage": "Navn",
   "moveNodeSingleMessage": "Er du sikker på at du vil flytte \"{0}\" til denne mappen?",
   "deleteNodeMultiMessage": "Er du sikker på at du vil fjerne {0} objekt?",
   "moveNodeMultiMessage": "Er du sikker på at du vil flytte {0} objekt til denne mappen?",
   "deleteNodeTitle": "Fjern",
   "moveNodeTitle": "Flytt",
   "deleteNodeSingleMessage": "Er du sikker på at du vil fjerne \"{0}\"?",
   "createFolderTitle": "Opprett mappe"
};
sv.i18n.portlet.social.fileview.browse.sv={
   "renameFolderTitle": "Byt namn på mapp",
   "renameFolderMessage": "Nytt namn",
   "createFolderMessage": "Namn",
   "moveNodeSingleMessage": "Är du säker på du vill flytta '{0}' till denna mapp?",
   "deleteNodeMultiMessage": "Är du säker på du vill ta bort {0} objekt?",
   "moveNodeMultiMessage": "Är du säker på du vill flytta {0} objekt denna mapp?",
   "deleteNodeTitle": "Ta bort",
   "moveNodeTitle": "Flytta",
   "deleteNodeSingleMessage": "Är du säker på du vill ta bort '{0}'?",
   "createFolderTitle": "Skapa mapp"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.calendar=sv.i18n.portlet.social.calendar || {};
sv.i18n.portlet.social.calendar.calendar=sv.i18n.portlet.social.calendar.calendar || {};
sv.i18n.portlet.social.calendar.calendar.de={
   "buttonTextDay": "Tag",
   "tue": "Di",
   "sat": "Sa",
   "mon": "Mo",
   "wed": "Mi",
   "sun": "So",
   "buttonTextAgendaList": "Liste",
   "buttonTextMonth": "Monat",
   "filterAll": "Nur meine",
   "buttonTextToday": "Heute",
   "buttonTextWeek": "Woche",
   "fri": "Fr",
   "thu": "Do"
};
sv.i18n.portlet.social.calendar.calendar.en={
   "tuesday": "Tuesday",
   "confirmRemoveMessage": "Are you sure you want to delete the event?",
   "sat": "Sat",
   "description": "Description",
   "buttonTextWeek": "Week",
   "allday": "All day",
   "jun": "Jun",
   "jul": "Jul",
   "apr": "Apr",
   "create": "Add event",
   "may": "May",
   "confirmRemoveTaskRemove": "Yes",
   "titleDay": "dddd d MMM yyyy",
   "mar": "Mar",
   "map": "Map",
   "sunday": "Sunday",
   "columnWeek": "dddd d/MM",
   "confirmRemoveTaskTitle": "Delete",
   "axisFormat": "HH:mm",
   "columnMonth": "ddd",
   "eventState": "Status",
   "february": "February",
   "january": "January",
   "more": "Read more",
   "wed": "Wed",
   "jan": "Jan",
   "sep": "Sep",
   "event": "Event",
   "aug": "Aug",
   "buttonTextAgendaList": "List",
   "filterMine": "Everybody's",
   "monday": "Monday",
   "filterAll": "Only mine",
   "april": "April",
   "timeFormatAgenda": "HH:mm{ - HH:mm}",
   "noOwner": "Specify an owner",
   "createEvent": "Event",
   "titleWeek": "d [MMM yyyy]{ - d MMM yyyy}",
   "october": "October",
   "timeFormat": "HH:mm",
   "name": "Title",
   "noEnd": "Specify an end date",
   "confirmRemoveTaskSaveInTasks": "No, save in the task list",
   "invalidAllDayTimePeriod": "End date must be the same or after start date",
   "friday": "Friday",
   "eventDetails": "Details",
   "invalidTimePeriod": "End time must be after start time",
   "wednesday": "Wednesday",
   "november": "November",
   "titleMonth": "MMMM yyyy",
   "june": "June",
   "fri": "Fri",
   "buttonTextDay": "Day",
   "owner": "Owner",
   "task": "Task",
   "confirmRemoveTitle": "Delete",
   "march": "March",
   "saturday": "Saturday",
   "december": "December",
   "august": "August",
   "sun": "Sun",
   "september": "September",
   "oct": "Oct",
   "tue": "Tue",
   "buttonTextToday": "Today",
   "buttonTextMonth": "Month",
   "thursday": "Thursday",
   "thu": "Thu",
   "feb": "Feb",
   "nov": "Nov",
   "july": "July",
   "columnDay": "dddd d/MM",
   "dec": "Dec",
   "participants": "Participants",
   "noTitle": "Specify a title",
   "noStart": "Specify a start date",
   "mon": "Mon",
   "confirmRemoveTaskMessage": "Would you also like to remove the task from the task list?",
   "week": "W"
};
sv.i18n.portlet.social.calendar.calendar.fi={
   "tuesday": "Tiistai",
   "confirmRemoveMessage": "Oletko varma, että haluat poistaa tapahtuman?",
   "sat": "La",
   "description": "Kuvaus",
   "buttonTextWeek": "Viikko",
   "allday": "Joka päivä",
   "jun": "kes",
   "jul": "hei",
   "apr": "huh",
   "create": "Luo tapahtuma",
   "may": "tou",
   "confirmRemoveTaskRemove": "Kyllä",
   "titleDay": "dddd d MMM yyyy",
   "mar": "maa",
   "map": "Kartta",
   "sunday": "Sunnuntai",
   "columnWeek": "dddd d/MM",
   "confirmRemoveTaskTitle": "Poista",
   "axisFormat": "HH:mm",
   "columnMonth": "ddd",
   "eventState": "Status",
   "february": "Helmikuu",
   "january": "Tammikuu",
   "more": "Lue lisää",
   "wed": "Ke",
   "jan": "tam",
   "sep": "syy",
   "event": "Tapahtuma",
   "aug": "elo",
   "buttonTextAgendaList": "Lista",
   "filterMine": "Kaikki",
   "monday": "Maanantai",
   "filterAll": "Vain minä",
   "april": "Huhtikuu",
   "timeFormatAgenda": "HH:mm{ - HH:mm}",
   "noOwner": "Lisää omistaja",
   "createEvent": "Tapahtuma",
   "titleWeek": "MMM d[, yyyy]{ -[ MMM] d, yyyy}",
   "october": "Lokakuu",
   "timeFormat": "HH:mm",
   "name": "Nimi",
   "noEnd": "Lisää päättymispäivä",
   "confirmRemoveTaskSaveInTasks": "Ei, säilytä listalla",
   "invalidAllDayTimePeriod": "Päättymispäivän tulee olla sama tai jälkeen aloituspäivän",
   "friday": "Perjantai",
   "eventDetails": "Detaljit",
   "invalidTimePeriod": "Päättymisajan tulee olla alkamisajan jälkeen",
   "wednesday": "Keskiviikko",
   "november": "Marraskuu",
   "titleMonth": "MMMM yyyy",
   "june": "Kesäkuu",
   "fri": "Pe",
   "buttonTextDay": "Päivä",
   "owner": "Omistaja",
   "task": "Toiminto",
   "confirmRemoveTitle": "Poista",
   "march": "Maaliskuu",
   "saturday": "Lauantai",
   "december": "Joulukuu",
   "august": "Elokuu",
   "sun": "Su",
   "september": "Syyskuu",
   "oct": "lok",
   "tue": "Ti",
   "buttonTextToday": "Tänään",
   "buttonTextMonth": "Kuukausi",
   "thursday": "Torstai",
   "thu": "To",
   "feb": "hel",
   "nov": "mar",
   "july": "Heinäkuu",
   "columnDay": "dddd d/MM",
   "dec": "jou",
   "participants": "Osanottaja",
   "noTitle": "Lisää otsikko",
   "noStart": "Lisää aloituspäivä",
   "mon": "Ma",
   "confirmRemoveTaskMessage": "Haluatko poistaa myös listasta?",
   "week": "Vko"
};
sv.i18n.portlet.social.calendar.calendar.fr={
   "april": "Avril",
   "february": "Février",
   "thu": "Jeu",
   "wed": "Mer",
   "sun": "Dim",
   "january": "Janvier",
   "sat": "Sam",
   "buttonTextToday": "Aujourd'hui",
   "october": "Octobre",
   "buttonTextMonth": "Mois",
   "march": "Mars",
   "september": "Septembre",
   "november": "Novembre",
   "buttonTextWeek": "Semaine",
   "july": "Juillet",
   "december": "Décembre",
   "mon": "Lun",
   "may": "Mai",
   "buttonTextDay": "Journée",
   "august": "Août",
   "june": "Juin",
   "tue": "Mar",
   "buttonTextAgendaList": "Liste",
   "fri": "Ven",
   "filterAll": "Seulement le mien"
};
sv.i18n.portlet.social.calendar.calendar.nl={
   "buttonTextDay": "Dag",
   "tue": "Di",
   "sat": "Za",
   "mon": "Ma",
   "wed": "Wo",
   "sun": "Zo",
   "buttonTextAgendaList": "Lijst",
   "buttonTextMonth": "Maand",
   "filterAll": "Alleen de mijne",
   "buttonTextToday": "Vandaag",
   "buttonTextWeek": "Week",
   "fri": "Vr",
   "thu": "Do"
};
sv.i18n.portlet.social.calendar.calendar.no={
   "tuesday": "Tirsdag",
   "confirmRemoveMessage": "Er du sikker på at du vil fjerne hendelsen?",
   "sat": "Lør",
   "description": "Beskrivelse",
   "buttonTextWeek": "Uke",
   "allday": "Heldag",
   "jun": "jun",
   "jul": "jul",
   "apr": "apr",
   "create": "Legg til hendelse",
   "may": "Mai",
   "confirmRemoveTaskRemove": "Ja",
   "titleDay": "dddd d MMM yyyy",
   "mar": "mar",
   "map": "Kart",
   "sunday": "Søndag",
   "columnWeek": "dddd d/MM",
   "confirmRemoveTaskTitle": "Fjern",
   "axisFormat": "HH:mm",
   "columnMonth": "ddd",
   "eventState": "Status",
   "february": "Februar",
   "january": "Januar",
   "more": "Les mer",
   "wed": "Ons",
   "jan": "jan",
   "sep": "sep",
   "event": "Hendelse",
   "aug": "aug",
   "buttonTextAgendaList": "Liste",
   "filterMine": "Allas",
   "monday": "Mandag",
   "filterAll": "Bare mine",
   "april": "April",
   "timeFormatAgenda": "HH:mm{ - HH:mm}",
   "noOwner": "Angi en eier",
   "createEvent": "Hendelse",
   "titleWeek": "d [MMM yyyy]{ - d MMM yyyy}",
   "october": "Oktober",
   "timeFormat": "HH:mm",
   "name": "Tittel",
   "noEnd": "Angi en sluttdato",
   "confirmRemoveTaskSaveInTasks": "Nei, lagre i oppgavelisten",
   "invalidAllDayTimePeriod": "Sluttdato må være samme eller etter startdato",
   "friday": "Fredag",
   "eventDetails": "Detaljer",
   "invalidTimePeriod": "Sluttid må være etter starttid",
   "wednesday": "Onsdag",
   "november": "November",
   "titleMonth": "MMMM yyyy",
   "june": "Juni",
   "fri": "Fre",
   "buttonTextDay": "Dag",
   "owner": "Eier",
   "task": "Opplysning",
   "confirmRemoveTitle": "Fjern",
   "march": "Mars",
   "saturday": "Lørdag",
   "december": "Desember",
   "august": "August",
   "sun": "Søn",
   "september": "September",
   "oct": "okt",
   "tue": "Tir",
   "buttonTextToday": "I dag",
   "buttonTextMonth": "Måned",
   "thursday": "Torsdag",
   "thu": "Tor",
   "feb": "feb",
   "nov": "nov",
   "july": "Juli",
   "columnDay": "dddd d/MM",
   "dec": "des",
   "participants": "Deltager",
   "noTitle": "Angi en tittel",
   "noStart": "Angi en startdato",
   "mon": "Man",
   "confirmRemoveTaskMessage": "Vil du også fjerne opplysningen fra oppgavelisten?",
   "week": "V"
};
sv.i18n.portlet.social.calendar.calendar.sv={
   "tuesday": "Tisdag",
   "confirmRemoveMessage": "Är du säker på att du vill ta bort händelsen?",
   "sat": "Lör",
   "description": "Beskrivning",
   "buttonTextWeek": "Vecka",
   "allday": "Heldag",
   "jun": "jun",
   "jul": "jul",
   "apr": "apr",
   "create": "Lägg till händelse",
   "may": "Maj",
   "confirmRemoveTaskRemove": "Ja",
   "titleDay": "dddd d MMM yyyy",
   "mar": "mar",
   "map": "Karta",
   "sunday": "Söndag",
   "columnWeek": "dddd d/MM",
   "confirmRemoveTaskTitle": "Ta bort",
   "axisFormat": "HH:mm",
   "columnMonth": "ddd",
   "eventState": "Status",
   "february": "Februari",
   "january": "Januari",
   "more": "Läs mer",
   "wed": "Ons",
   "jan": "jan",
   "sep": "sep",
   "event": "Händelse",
   "aug": "aug",
   "buttonTextAgendaList": "Lista",
   "filterMine": "Allas",
   "monday": "Måndag",
   "filterAll": "Endast mina",
   "april": "April",
   "timeFormatAgenda": "HH:mm{ - HH:mm}",
   "noOwner": "Ange en ägare",
   "createEvent": "Händelse",
   "titleWeek": "d [MMM yyyy]{ - d MMM yyyy}",
   "october": "Oktober",
   "timeFormat": "HH:mm",
   "name": "Titel",
   "noEnd": "Ange ett slutdatum",
   "confirmRemoveTaskSaveInTasks": "Nej, spara i uppgiftslistan",
   "invalidAllDayTimePeriod": "Slutdatum måste vara samma eller efter startdatum",
   "friday": "Fredag",
   "eventDetails": "Detaljer",
   "invalidTimePeriod": "Sluttid måste vara efter starttid",
   "wednesday": "Onsdag",
   "november": "November",
   "titleMonth": "MMMM yyyy",
   "june": "Juni",
   "fri": "Fre",
   "buttonTextDay": "Dag",
   "owner": "Ägare",
   "task": "Uppgift",
   "confirmRemoveTitle": "Ta bort",
   "march": "Mars",
   "saturday": "Lördag",
   "december": "December",
   "august": "Augusti",
   "sun": "Sön",
   "september": "September",
   "oct": "okt",
   "tue": "Tis",
   "buttonTextToday": "Idag",
   "buttonTextMonth": "Månad",
   "thursday": "Torsdag",
   "thu": "Tor",
   "feb": "feb",
   "nov": "nov",
   "july": "Juli",
   "columnDay": "dddd d/MM",
   "dec": "dec",
   "participants": "Deltagare",
   "noTitle": "Ange en titel",
   "noStart": "Ange ett startdatum",
   "mon": "Mån",
   "confirmRemoveTaskMessage": "Vill du även ta bort uppgiften från uppgiftslistan?",
   "week": "V"
};
sv.i18n.common=sv.i18n.common || {};
sv.i18n.common.de={
   "help": "Hilfe",
   "error": "Fehler",
   "ok": "OK",
   "new": "Neu",
   "cancel": "Abbrechen",
   "save": "Spechern",
   "close": "Schließen"
};
sv.i18n.common.en={
   "networkErrorText": "Information could not be updated. Could not contact server.",
   "networkErrorTitle": "Communication with server failed",
   "decimal": ".",
   "cancel": "Cancel",
   "mins": "mins",
   "error-fileExists": "{0} already exists.",
   "ok": "OK",
   "error-fileRenameHeading": "Could not rename file",
   "add": "Add",
   "close": "Close",
   "new": "New",
   "error": "Error",
   "error-fileExistsHeading": "Could not add file",
   "help": "Help",
   "save": "Save",
   "hrs": "hrs",
   "error-unknown": "Unknown error.",
   "remove": "Delete",
   "hr": "hr"
};
sv.i18n.common.fi={
   "networkErrorText": "Aineistoa ei voitu päivittää. Palvelimeen ei saada yhteyttä.",
   "networkErrorTitle": "Ongelma palvelinyhteydessä.",
   "decimal": ",",
   "cancel": "Peruuta",
   "mins": "min",
   "error-fileExists": "{0} tiedosto on jo olemassa.",
   "ok": "OK",
   "error-fileRenameHeading": "Tiedoston nimen vaihto ei onnistunut",
   "add": "Luo tili",
   "close": "Sulje",
   "new": "Uusi",
   "error": "Virhe",
   "error-fileExistsHeading": "Tiedoston lisääminen ei onnistunut",
   "help": "Ohje",
   "save": "Tallenna",
   "hrs": "tuntia",
   "error-unknown": "Tuntematon virhe.",
   "remove": "Poista",
   "hr": "tunti"
};
sv.i18n.common.fr={"cancel": "Annuler"};
sv.i18n.common.no={
   "networkErrorText": "Informasjonen kunne ikke oppdateres. Kunne ikke kontakte serveren.",
   "networkErrorTitle": "Feil ved serverkommunikasjon",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finnes allerede.",
   "ok": "OK",
   "error-fileRenameHeading": "Det var ikke mulig å bytte navn på filen",
   "add": "Legg til",
   "close": "Lukk",
   "new": "Ny",
   "error": "Feil",
   "error-fileExistsHeading": "Det var ikke mulig å legge til filen",
   "help": "Hjelp",
   "save": "Lagre",
   "hrs": "time",
   "error-unknown": "Ukjent feil.",
   "remove": "Fjern",
   "hr": "time"
};
sv.i18n.common.sv={
   "networkErrorText": "Informationen kunde inte uppdateras. Kunde inte kontakta servern.",
   "networkErrorTitle": "Fel vid serverkommunikation",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finns redan.",
   "ok": "OK",
   "error-fileRenameHeading": "Det gick inte att byta namn på filen",
   "add": "Lägg till",
   "close": "Stäng",
   "new": "Ny",
   "error": "Fel",
   "error-fileExistsHeading": "Det gick inte att lägga till filen",
   "help": "Hjälp",
   "save": "Spara",
   "hrs": "tim",
   "error-unknown": "Okänt fel.",
   "remove": "Ta bort",
   "hr": "tim"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.contactsearch=sv.i18n.portlet.social.contactsearch || {};
sv.i18n.portlet.social.contactsearch.contactsearch=sv.i18n.portlet.social.contactsearch.contactsearch || {};
sv.i18n.portlet.social.contactsearch.contactsearch.de={};
sv.i18n.portlet.social.contactsearch.contactsearch.en={
   "showList": "Show in list »",
   "results": "search results",
   "showAll": "Show all »",
   "noHits": "Your search generated no hits"
};
sv.i18n.portlet.social.contactsearch.contactsearch.fi={
   "showList": "Näytä listalla »",
   "showAll": "Näytä kaikki henkilöt »",
   "noHits": "Haku ei tuottanut tuloksia"
};
sv.i18n.portlet.social.contactsearch.contactsearch.no={
   "showList": "Vis i liste »",
   "showAll": "Vis flere personer »",
   "noHits": "Søket ga ingen treff"
};
sv.i18n.portlet.social.contactsearch.contactsearch.sv={
   "showList": "Visa i lista »",
   "results": "sökresultat",
   "showAll": "Visa fler personer »",
   "noHits": "Din sökning gav inga träffar"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.createdocument=sv.i18n.portlet.social.createdocument || {};
sv.i18n.portlet.social.createdocument.createdocument=sv.i18n.portlet.social.createdocument.createdocument || {};
sv.i18n.portlet.social.createdocument.createdocument.de={};
sv.i18n.portlet.social.createdocument.createdocument.en={
   "location": "Location",
   "name": "Name",
   "tags": "Tags",
   "create": "Create",
   "cancel": "Cancel",
   "createDocumentTitle": "Create document"
};
sv.i18n.portlet.social.createdocument.createdocument.fi={};
sv.i18n.portlet.social.createdocument.createdocument.no={
   "location": "Plass",
   "name": "Navn",
   "tags": "Etiketter",
   "create": "Opprett",
   "cancel": "Avbryt",
   "createDocumentTitle": "Opprett dokument"
};
sv.i18n.portlet.social.createdocument.createdocument.sv={
   "location": "Plats",
   "name": "Namn",
   "tags": "Etiketter",
   "create": "Skapa",
   "cancel": "Avbryt",
   "createDocumentTitle": "Skapa dokument"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.fileview=sv.i18n.portlet.social.fileview || {};
sv.i18n.portlet.social.fileview.file=sv.i18n.portlet.social.fileview.file || {};
sv.i18n.portlet.social.fileview.file.de={};
sv.i18n.portlet.social.fileview.file.en={
   "emptyDescription": "No description",
   "showLess": "Show less",
   "versionDescriptionTitle": "Description",
   "download": "Download",
   "versionDescriptionMsg": "Description",
   "disableVersioning": "Are you sure you want to disable versioning? All previous versions will be deleted!",
   "shareEntry": "Share timeline post",
   "removeCommentBody": "Are you sure you want to delete the comment?",
   "emptyText": "No value",
   "sharedMessageContent": "Message is now shared to your followers",
   "removeCommentTitle": "Delete comment",
   "unLockMessage": "The file is now unlocked",
   "lockErrorMessage": "File already locked by {0}",
   "renameFileTitle": "Rename",
   "unlockErrorMessage": "File locked by {0}",
   "commentToLongHeading": "Too long",
   "renameFileMessage": "Name",
   "lockMessage": "File locked by {0}",
   "sharedMessageTitle": "Shared message",
   "share": "Share",
   "shareComment": "Add comment",
   "editCommentTitle": "Edit comment",
   "save": "Save",
   "commentToLongBody": "The comment you where trying to post was too long.",
   "showMore": "Show more",
   "removeFile": "Are you sure you want to delete this file?",
   "removeVersion": "Are you sure you want to delete this file version?",
   "versioning": "Versioning",
   "remove": "Delete",
   "name": "Name"
};
sv.i18n.portlet.social.fileview.file.fi={
   "emptyDescription": "Ei kuvausta",
   "showLess": "Näytä vähemmän",
   "versionDescriptionTitle": "Kuvaus",
   "download": "Lataa",
   "versionDescriptionMsg": "Kuvaus",
   "disableVersioning": "Oletko varma, että haluat lopettaa versioinnin? Kaikki vanhat versiot poistuvat.",
   "shareEntry": "Jaa sisältö",
   "removeCommentBody": "Oletko varma, että haluat poistaa kommentin?",
   "emptyText": "Ei sisältöä",
   "sharedMessageContent": "Viesti on nyt jaettu seinälläsi",
   "removeCommentTitle": "Poista kommentti",
   "unLockMessage": "Tiedosto on nyt vapautettu",
   "lockErrorMessage": "Tiedosto on lukittu {0}",
   "renameFileTitle": "Vaihda nimi",
   "unlockErrorMessage": "Tiedosto on vapautettu {0}",
   "commentToLongHeading": "Liian pitkä",
   "renameFileMessage": "Nimi",
   "lockMessage": "Tiedosto on nyt lukittu {0}",
   "sharedMessageTitle": "Jaettu viesti",
   "share": "Jaa",
   "shareComment": "Jaa kommentti",
   "commentToLongBody": "Kommentti, jota yritit lähettää, on liian pitkä.",
   "showMore": "Näytä lisää",
   "removeFile": "Oletko varma, että haluat poistaa tiedoston?",
   "removeVersion": "Oletko varma, että haluat poistaa tämänhetkisen version tiedostosta?",
   "versioning": "Versiointi",
   "remove": "Poista",
   "name": "Nimi"
};
sv.i18n.portlet.social.fileview.file.no={
   "emptyDescription": "Ingen beskrivelse",
   "showLess": "Vis mindre",
   "versionDescriptionTitle": "Beskrivelse",
   "download": "Last ned",
   "versionDescriptionMsg": "Beskrivelse",
   "disableVersioning": "Er du sikker på at du vil slå av versjonering, alle gamle versjoner kommer til å forsvinne?",
   "shareEntry": "Del innlegg",
   "removeCommentBody": "Er du sikker på at du vil fjerne kommentaren?",
   "emptyText": "Ingen verdi",
   "sharedMessageContent": "Meldingen er nå delt i din flyt",
   "removeCommentTitle": "Fjern kommentar",
   "unLockMessage": "Filen er nå opplåst",
   "lockErrorMessage": "Filen er allerede låst av {0}",
   "renameFileTitle": "Bytt navn",
   "unlockErrorMessage": "Filen er låst av {0}",
   "commentToLongHeading": "For lang",
   "renameFileMessage": "Navn",
   "lockMessage": "Filen er nå låst av {0}",
   "sharedMessageTitle": "Delt melding",
   "share": "Del",
   "shareComment": "Legg til kommentar",
   "editCommentTitle": "Rediger kommentar",
   "save": "Lagre",
   "commentToLongBody": "Kommentaren du forsøkte å sende, var for lang.",
   "showMore": "Vis mer",
   "removeFile": "Er du sikker på at du vil fjerne aktuell fil?",
   "removeVersion": "Er du sikker på at du vil fjerne aktuell versjon av filen?",
   "versioning": "Versjonering",
   "remove": "Fjern",
   "name": "Navn"
};
sv.i18n.portlet.social.fileview.file.sv={
   "emptyDescription": "Ingen beskrivning",
   "showLess": "Visa mindre",
   "versionDescriptionTitle": "Beskrivning",
   "download": "Ladda ner",
   "versionDescriptionMsg": "Beskrivning",
   "disableVersioning": "Är du säker på att du vill slå av versionering, alla gamla verisoner kommer att försvinna?",
   "shareEntry": "Dela inlägg",
   "removeCommentBody": "Är du säker på att du vill ta bort kommentaren?",
   "emptyText": "Inget värde",
   "sharedMessageContent": "Meddelandet är nu delat i ditt flöde",
   "removeCommentTitle": "Ta bort kommentar",
   "unLockMessage": "Filen är nu upplåst",
   "lockErrorMessage": "Filen är redan låst av {0}",
   "renameFileTitle": "Byt namn",
   "unlockErrorMessage": "Filen är låst av {0}",
   "commentToLongHeading": "För lång",
   "renameFileMessage": "Namn",
   "lockMessage": "Filen är nu låst av {0}",
   "sharedMessageTitle": "Delat meddelande",
   "share": "Dela",
   "shareComment": "Lägg till kommentar",
   "editCommentTitle": "Redigera kommentar",
   "save": "Spara",
   "commentToLongBody": "Kommentaren du försökte skicka var för lång.",
   "showMore": "Visa mer",
   "removeFile": "Är du säker på att du vill ta bort aktuell fil?",
   "removeVersion": "Är du säker på att du vill ta bort aktuell version av filen?",
   "versioning": "Versionering",
   "remove": "Ta bort",
   "name": "Namn"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.filelist=sv.i18n.portlet.social.filelist || {};
sv.i18n.portlet.social.filelist.filelist=sv.i18n.portlet.social.filelist.filelist || {};
sv.i18n.portlet.social.filelist.filelist.de={};
sv.i18n.portlet.social.filelist.filelist.en={"showAllFiles": "Show all files ({0})"};
sv.i18n.portlet.social.filelist.filelist.fi={"showAllFiles": "Näytä kaikki tiedostot ({0})"};
sv.i18n.portlet.social.filelist.filelist.no={"showAllFiles": "Vis alle filer ({0})"};
sv.i18n.portlet.social.filelist.filelist.sv={"showAllFiles": "Visa alla filer ({0})"};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.groupadminall=sv.i18n.portlet.social.groupadminall || {};
sv.i18n.portlet.social.groupadminall.groupadminall=sv.i18n.portlet.social.groupadminall.groupadminall || {};
sv.i18n.portlet.social.groupadminall.groupadminall.de={};
sv.i18n.portlet.social.groupadminall.groupadminall.en={
   "successAddAdminMessage": "{0} is now an administrator",
   "confirmRemoveMessage": "Are you sure you want to remove {0} from the group?",
   "searchUser": "Search user",
   "successMessage": "{0} is no longer a member of the group",
   "confirmRemoveTitle": "Remove",
   "successAddMessageTitle": "Info",
   "successAddMessage": "{0} is now a member of the group"
};
sv.i18n.portlet.social.groupadminall.groupadminall.fi={
   "successAddAdminMessage": "{0} on nyt moderaattori",
   "searchUser": "Hae henkilöä",
   "successMessage": "{0} on nyt poistettu ryhmästä",
   "successAddMessageTitle": "Informaatio",
   "successAddMessage": "{0} on nyt ryhmän jäsen"
};
sv.i18n.portlet.social.groupadminall.groupadminall.no={
   "successAddAdminMessage": "{0} er nå adminstrator",
   "confirmRemoveMessage": "Er du sikker på at du vil fjerne {0} fra gruppen?",
   "searchUser": "Søk person",
   "successMessage": "{0} er nå fjernet fra gruppen",
   "confirmRemoveTitle": "Fjern",
   "successAddMessageTitle": "Info",
   "successAddMessage": "{0} er nå medlem av gruppen"
};
sv.i18n.portlet.social.groupadminall.groupadminall.sv={
   "successAddAdminMessage": "{0} är nu adminstratör",
   "confirmRemoveMessage": "Är du säker på att du vill ta bort {0} från gruppen?",
   "searchUser": "Sök person",
   "successMessage": "{0} är nu borttagen ur gruppen",
   "confirmRemoveTitle": "Ta bort",
   "successAddMessageTitle": "Info",
   "successAddMessage": "{0} är nu medlem i gruppen"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.grouptypeselector=sv.i18n.portlet.social.grouptypeselector || {};
sv.i18n.portlet.social.grouptypeselector.grouptypeselector=sv.i18n.portlet.social.grouptypeselector.grouptypeselector || {};
sv.i18n.portlet.social.grouptypeselector.grouptypeselector.de={};
sv.i18n.portlet.social.grouptypeselector.grouptypeselector.en={
   "searchUser": "Search administrator",
   "unpublishGroupBody": "Are you sure that you want to delete this group?",
   "groupAlreadyExists": "A group with the same already exists",
   "errorTitle": "Error",
   "unpublishGroupTitle": "Delete group",
   "group-settingsLabel": "Group settings"
};
sv.i18n.portlet.social.grouptypeselector.grouptypeselector.fi={
   "searchUser": "Etsi moderaattoria",
   "groupAlreadyExists": "Tämän niminen ryhmä on jo olemassa",
   "errorTitle": "Virhe",
   "group-settingsLabel": "Ryhmän asetukset"
};
sv.i18n.portlet.social.grouptypeselector.grouptypeselector.no={
   "searchUser": "Søk administrator",
   "unpublishGroupBody": "Er du sikker på at du vil fjerne denne gruppen?",
   "groupAlreadyExists": "Det finnes allerede en gruppe med samme navn",
   "errorTitle": "Feil",
   "unpublishGroupTitle": "Fjern gruppe",
   "group-settingsLabel": "Gruppeinnstillinger"
};
sv.i18n.portlet.social.grouptypeselector.grouptypeselector.sv={
   "searchUser": "Sök administratör",
   "unpublishGroupBody": "Är du säker på att du vill ta bort denna grupp?",
   "groupAlreadyExists": "En grupp med samma namn finns redan",
   "errorTitle": "Fel",
   "unpublishGroupTitle": "Ta bort grupp",
   "group-settingsLabel": "Gruppinställningar"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.util=sv.i18n.portlet.social.util || {};
sv.i18n.portlet.social.util.likableutil=sv.i18n.portlet.social.util.likableutil || {};
sv.i18n.portlet.social.util.likableutil.de={"like": "Like"};
sv.i18n.portlet.social.util.likableutil.en={
   "likes": "Likes",
   "like": "Like"
};
sv.i18n.portlet.social.util.likableutil.fi={
   "likes": "Tykkää",
   "like": "Tykkäys"
};
sv.i18n.portlet.social.util.likableutil.fr={"like": "J'aime"};
sv.i18n.portlet.social.util.likableutil.nl={"like": "Like"};
sv.i18n.portlet.social.util.likableutil.no={
   "likes": "Liker",
   "like": "Lik"
};
sv.i18n.portlet.social.util.likableutil.sv={
   "likes": "Gillar",
   "like": "Gilla"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.messages=sv.i18n.portlet.social.messages || {};
sv.i18n.portlet.social.messages.messages=sv.i18n.portlet.social.messages.messages || {};
sv.i18n.portlet.social.messages.messages.de={
   "newMessage": "Neue nachricht...",
   "conversations": "Posteingang",
   "placeholder": "Hier Nachricht schreiben..."
};
sv.i18n.portlet.social.messages.messages.en={
   "newMember": "New chat member",
   "newMessage": "New message...",
   "participants": "Add recipients...",
   "typing": "is typing...",
   "conversations": "Inbox",
   "placeholder": "Write your message here...",
   "showMore": "Show more conversations"
};
sv.i18n.portlet.social.messages.messages.fi={
   "newMember": "Uusi viesti keskustelussa",
   "participants": "Lähetä vastaanottajalle...",
   "typing": "kirjoittaa...",
   "conversations": "Postilaatikko",
   "placeholder": "Kirjoita viestisi tähän...",
   "showMore": "Näytä lisää"
};
sv.i18n.portlet.social.messages.messages.fr={
   "newMessage": "Nouveau message...",
   "conversations": "Boîte de réception",
   "placeholder": "Ecrivez votre message ici..."
};
sv.i18n.portlet.social.messages.messages.nl={
   "newMessage": "Nieuw bericht...",
   "conversations": "Inbox",
   "placeholder": "Schrijf je bericht hier..."
};
sv.i18n.portlet.social.messages.messages.no={
   "newMember": "Nytt medlem i chatten",
   "newMessage": "Ny melding ...",
   "participants": "Legg til mottager ...",
   "typing": "skriver ...",
   "conversations": "Innboks",
   "placeholder": "Skriv din melding her ...",
   "showMore": "Vis flere samtaler"
};
sv.i18n.portlet.social.messages.messages.sv={
   "newMember": "Ny medlem i chatten",
   "newMessage": "Nytt meddelande...",
   "participants": "Lägg till mottagare...",
   "typing": "skriver...",
   "conversations": "Inkorg",
   "placeholder": "Skriv ditt meddelande här...",
   "showMore": "Visa fler konversationer"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.notifications=sv.i18n.portlet.social.notifications || {};
sv.i18n.portlet.social.notifications.notifications=sv.i18n.portlet.social.notifications.notifications || {};
sv.i18n.portlet.social.notifications.notifications.de={
   "notifications": "Benachrichtigungen",
   "showMore": "Mehr Benachrichtigungen anzeigen"
};
sv.i18n.portlet.social.notifications.notifications.en={
   "error-unknown": "Unable to read notifications",
   "notifications": "Notifications",
   "showMore": "Show more notifications"
};
sv.i18n.portlet.social.notifications.notifications.fi={
   "error-unknown": "Luku epäonnistui",
   "notifications": "Merkinnät",
   "showMore": "Näytä lisää merkintöjä"
};
sv.i18n.portlet.social.notifications.notifications.fr={"showMore": "Afficher plus de notifications"};
sv.i18n.portlet.social.notifications.notifications.nl={"showMore": "Toon meer notificaties"};
sv.i18n.portlet.social.notifications.notifications.no={
   "error-unknown": "Kunne ikke lese inn varsler",
   "notifications": "Varsler",
   "showMore": "Vis flere varsler"
};
sv.i18n.portlet.social.notifications.notifications.sv={
   "error-unknown": "Kunde inte läsa in notifieringar",
   "notifications": "Notifieringar",
   "showMore": "Visa fler notifieringar"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.personalsettings=sv.i18n.portlet.social.personalsettings || {};
sv.i18n.portlet.social.personalsettings.personalsettings=sv.i18n.portlet.social.personalsettings.personalsettings || {};
sv.i18n.portlet.social.personalsettings.personalsettings.de={
   "personalsettings-notificationsLabel": "Benachrichtigungs-Einstellungen",
   "personalsettings-generalLabel": "Grundeinstellungen",
   "personalsettings-notificationsHelp": "E-Mail-Benachrichtigung senden, wenn:",
   "personalsettings-push-notificationsLabel": "Benachrichtigungs-Einstellungen"
};
sv.i18n.portlet.social.personalsettings.personalsettings.en={
   "personalsettings-notificationsLabel": "Notification settings",
   "personalsettings-generalLabel": "General settings",
   "personalsettings-notificationsHelp": "Send email notifications when:",
   "networkErrorTitle": "Communication with server failed",
   "networkErrorText": "Information could not be updated. Could not contact server.",
   "personalsettings-push-notificationsLabel": "Notification settings"
};
sv.i18n.portlet.social.personalsettings.personalsettings.fi={
   "personalsettings-notificationsLabel": "Merkintöjen asetukset",
   "personalsettings-generalLabel": "Yleiset asetukset",
   "personalsettings-notificationsHelp": "Lähetä ilmoitus sähköpostilla, kun:",
   "networkErrorTitle": "Ongelma palvelinyhteydessä",
   "networkErrorText": "Informaatiota ei voitu päivittää. Palvelimeen ei saada yhteyttä."
};
sv.i18n.portlet.social.personalsettings.personalsettings.fr={
   "personalsettings-notificationsLabel": "Paramètres de notification",
   "personalsettings-generalLabel": "Paramètres généraux",
   "personalsettings-notificationsHelp": "Envoyer des notifications par e-mail quand:",
   "personalsettings-push-notificationsLabel": "Paramètres de notification"
};
sv.i18n.portlet.social.personalsettings.personalsettings.nl={
   "personalsettings-notificationsLabel": "Notificatie instellingen",
   "personalsettings-generalLabel": "Algemene instellingen",
   "personalsettings-notificationsHelp": "Verstuur email notificaties als:",
   "personalsettings-push-notificationsLabel": "Notificatie instellingen"
};
sv.i18n.portlet.social.personalsettings.personalsettings.no={
   "personalsettings-notificationsLabel": "Varselinnstillinger",
   "personalsettings-generalLabel": "Generelle innstillinger",
   "personalsettings-notificationsHelp": "Send varsler via e-post når:",
   "networkErrorTitle": "Feil ved serverkommunikasjon",
   "networkErrorText": "Informasjonen kunne ikke oppdateres. Kunne ikke kontakte serveren.",
   "personalsettings-push-notificationsLabel": "Varselinnstillinger"
};
sv.i18n.portlet.social.personalsettings.personalsettings.sv={
   "personalsettings-notificationsLabel": "Notifieringsinställningar",
   "personalsettings-generalLabel": "Allmänna inställningar",
   "personalsettings-notificationsHelp": "Skicka notifieringar via e-post när:",
   "networkErrorTitle": "Fel vid serverkommunikation",
   "networkErrorText": "Informationen kunde inte uppdateras. Kunde inte kontakta servern.",
   "personalsettings-push-notificationsLabel": "Notifieringsinställningar"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.profileimage=sv.i18n.portlet.social.profileimage || {};
sv.i18n.portlet.social.profileimage.profileimage=sv.i18n.portlet.social.profileimage.profileimage || {};
sv.i18n.portlet.social.profileimage.profileimage.de={};
sv.i18n.portlet.social.profileimage.profileimage.en={
   "error-dimension": "The image is too small. The minimum size is {0} x {1} pixels",
   "crop-title": "Crop the image",
   "error-unknown": "Unable to upload image",
   "error-size": "The image is too big. Maximum size is {0}MB",
   "error-type": "Invalid file type {0}. Only image files are permitted (jpg, png and gif)"
};
sv.i18n.portlet.social.profileimage.profileimage.fi={
   "error-dimension": "Kuva on liian pieni. Vähimmäistiedostokoko on {0} x {1} pikseliä",
   "crop-title": "Rajaa kuvaa",
   "error-unknown": "Kuvaa ei voitu ladata",
   "error-size": "Kuva on liian suuri. Maksimi tiedostokoko on {0}MB",
   "error-type": "Väärä tiedostomuoto {0}. Vain kuvat hyväksytään (jpg, png ja gif)"
};
sv.i18n.portlet.social.profileimage.profileimage.no={
   "error-dimension": "Bildet er for lite. Min. størrelse er {0} x {1} piksler",
   "crop-title": "Beskjær bildet",
   "error-unknown": "Kunne ikke laste opp bildet",
   "error-size": "Bildet er for stort. Maks. størrelse er {0}MB",
   "error-type": "Feil filtype {0}. Bare bilder tillates (jpg, png og gif)"
};
sv.i18n.portlet.social.profileimage.profileimage.sv={
   "error-dimension": "Bilden är för liten. Minimal storlek är {0} x {1} pixlar",
   "crop-title": "Beskär bilden",
   "error-unknown": "Kunde inte ladda upp bilden",
   "error-size": "Bilden är för stor. Maximal storlek är {0}MB",
   "error-type": "Felaktig filtyp {0}. Endast bilder tillåts (jpg, png and gif)"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.profileprogress=sv.i18n.portlet.social.profileprogress || {};
sv.i18n.portlet.social.profileprogress.profileprogress=sv.i18n.portlet.social.profileprogress.profileprogress || {};
sv.i18n.portlet.social.profileprogress.profileprogress.de={};
sv.i18n.portlet.social.profileprogress.profileprogress.en={
   "missingValue": "Missing value",
   "userFields": "User fields",
   "oneEntry": "At least one post in timeline",
   "hasContact": "At least one contact",
   "inGroup": "Member of at least one group",
   "helpPage": "Help page",
   "hasProfileImage": "Personal profile image",
   "collaborationCriteria": "Collaboration criteria",
   "profileCriteria": "Profile criteria",
   "incomplete": "Not met",
   "profileProgress": "Profile progress"
};
sv.i18n.portlet.social.profileprogress.profileprogress.fi={
   "missingValue": "Arvo puuttuu",
   "userFields": "Käyttäjäkenttä",
   "oneEntry": "Vähintään yksi merkintä seinällä",
   "hasContact": "Vähintään yksi yhteystieto",
   "inGroup": "Jäsen vähintään yhdessä ryhmässä",
   "helpPage": "Tukisivu",
   "hasProfileImage": "Oma profilikuva",
   "collaborationCriteria": "Ryhmäkriteerit",
   "profileCriteria": "Profiilikriteerit",
   "incomplete": "Ei lisätty"
};
sv.i18n.portlet.social.profileprogress.profileprogress.no={
   "missingValue": "Mangler verdi",
   "userFields": "Brukerfelt",
   "oneEntry": "Minst ett innlegg i aktivitetsstrømmen",
   "hasContact": "Minst én kontakt",
   "inGroup": "Medlem av minst én gruppe",
   "helpPage": "Hjelpeside",
   "hasProfileImage": "Eget profilbilde",
   "collaborationCriteria": "Samarbeidskriterier",
   "profileCriteria": "Profilkriterier",
   "incomplete": "Ikke oppfylt",
   "profileProgress": "Profilstyrke"
};
sv.i18n.portlet.social.profileprogress.profileprogress.sv={
   "missingValue": "Saknar värde",
   "userFields": "Användarfält",
   "oneEntry": "Minst ett inlägg i aktivitetsflödet",
   "hasContact": "Minst en kontakt",
   "inGroup": "Medlem i minst en grupp",
   "helpPage": "Hjälpsida",
   "hasProfileImage": "Egen profilbild",
   "collaborationCriteria": "Samarbetskriterier",
   "profileCriteria": "Profilkriterier",
   "incomplete": "Ej uppfyllt",
   "profileProgress": "Profilstyrka"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.util=sv.i18n.portlet.social.util || {};
sv.i18n.portlet.social.util.searchuserutil=sv.i18n.portlet.social.util.searchuserutil || {};
sv.i18n.portlet.social.util.searchuserutil.de={};
sv.i18n.portlet.social.util.searchuserutil.en={
   "showAsList": "Show in list »",
   "showAll": "Show all »"
};
sv.i18n.portlet.social.util.searchuserutil.fi={
   "showAsList": "Näytä listana »",
   "showAll": "Näytä kaikki hakutulokset »"
};
sv.i18n.portlet.social.util.searchuserutil.no={
   "showAsList": "Vis i liste »",
   "showAll": "Vis alle søketreff »"
};
sv.i18n.portlet.social.util.searchuserutil.sv={
   "showAsList": "Visa i lista »",
   "showAll": "Visa alla sökträffar »"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.sharedlinks=sv.i18n.portlet.social.sharedlinks || {};
sv.i18n.portlet.social.sharedlinks.sharedlinks=sv.i18n.portlet.social.sharedlinks.sharedlinks || {};
sv.i18n.portlet.social.sharedlinks.sharedlinks.de={};
sv.i18n.portlet.social.sharedlinks.sharedlinks.en={
   "placeholderUrl": "http://www.example.com",
   "createdSharedLink": "Share a link",
   "urlIsNotALink": "Invalid address",
   "editSharedLink": "Edit a link",
   "titleRequired": "Title required",
   "save": "Save",
   "removeLink": "Remove link",
   "title": "Title",
   "address": "Address",
   "removeShareLink": "Are you sure that you want to delete the link?",
   "share": "Share",
   "addressRequired": "Address required"
};
sv.i18n.portlet.social.sharedlinks.sharedlinks.fi={};
sv.i18n.portlet.social.sharedlinks.sharedlinks.no={
   "placeholderUrl": "http://www.eksempel.no",
   "createdSharedLink": "Del en lenke",
   "urlIsNotALink": "Ugyldig adresse",
   "editSharedLink": "Rediger en lenke",
   "titleRequired": "Det må angis en tittel",
   "save": "Lagre",
   "removeLink": "Fjern lenke",
   "title": "Tittel",
   "address": "Adresse",
   "removeShareLink": "Er du sikker på at du vil fjerne lenken?",
   "share": "Del",
   "addressRequired": "Det må angis en adresse"
};
sv.i18n.portlet.social.sharedlinks.sharedlinks.sv={
   "placeholderUrl": "http://www.exempel.se",
   "createdSharedLink": "Dela en länk",
   "urlIsNotALink": "Ogiltig adress",
   "editSharedLink": "Redigera en länk",
   "titleRequired": "Titel måste anges",
   "save": "Spara",
   "removeLink": "Ta bort länk",
   "title": "Titel",
   "address": "Adress",
   "removeShareLink": "Är du säker på att du vill ta bort länken?",
   "share": "Dela",
   "addressRequired": "Adress måste anges"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.taglist=sv.i18n.portlet.social.taglist || {};
sv.i18n.portlet.social.taglist.taglist=sv.i18n.portlet.social.taglist.taglist || {};
sv.i18n.portlet.social.taglist.taglist.de={};
sv.i18n.portlet.social.taglist.taglist.en={
   "addDialogLabel": "Tag:",
   "removedMessage": "The tag {0} was removed.",
   "addDialogTitle": "Add tag"
};
sv.i18n.portlet.social.taglist.taglist.fi={
   "addDialogLabel": "Risuaita:",
   "removedMessage": "Risuaita {0} on poistettu.",
   "addDialogTitle": "Lisää risuaita"
};
sv.i18n.portlet.social.taglist.taglist.no={
   "addDialogLabel": "Etikett:",
   "removedMessage": "Etiketten {0} er fjernet.",
   "addDialogTitle": "Legg til etikett"
};
sv.i18n.portlet.social.taglist.taglist.sv={
   "addDialogLabel": "Etikett:",
   "removedMessage": "Etiketten {0} har tagits bort.",
   "addDialogTitle": "Lägg till etikett"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.tasks=sv.i18n.portlet.social.tasks || {};
sv.i18n.portlet.social.tasks.tasks=sv.i18n.portlet.social.tasks.tasks || {};
sv.i18n.portlet.social.tasks.tasks.de={};
sv.i18n.portlet.social.tasks.tasks.en={
   "owner": "Owner",
   "participants": "Participants",
   "more": "More",
   "noTitle": "Specify a title",
   "confirmRemoveMessage": "Are you sure you want to delete the task?",
   "noOwner": "Specify an owner",
   "confirmRemoveCalendarSaveInCalendar": "No, save in the calendar",
   "invalidAllDayTimePeriod": "End date must be the same or after start date",
   "filter": "Show",
   "noStart": "Specify a start date",
   "confirmRemoveCalendarMessage": "Would you also like to delete the task from the calendar?",
   "isDone": "is done",
   "confirmRemoveCalendarTitle": "Delete",
   "confirmRemoveTitle": "Delete",
   "task": "Task",
   "doneText": "{0} of {1} are completed",
   "confirmRemoveCalendarRemove": "Yes",
   "noEnd": "Specify an end date",
   "map": "Map",
   "invalidTimePeriod": "End time must be after start time"
};
sv.i18n.portlet.social.tasks.tasks.fi={
   "owner": "Omistaja",
   "participants": "Osanottajat",
   "more": "Lue lisää",
   "noTitle": "Anna nimi",
   "confirmRemoveMessage": "Oletko varma, että haluat poistaa merkinnät?",
   "noOwner": "Syötä omistaja",
   "confirmRemoveCalendarSaveInCalendar": "Ei, säilytä kalenterissa",
   "invalidAllDayTimePeriod": "Päättymisajan pitää olla sama tai jälkeen aloitusajan",
   "filter": "Näytä",
   "noStart": "Syötä aloituspäivä",
   "confirmRemoveCalendarMessage": "Haluatko poistaa merkinnän myös kalenterista?",
   "isDone": "on tehty",
   "confirmRemoveCalendarTitle": "Poista",
   "confirmRemoveTitle": "Poista",
   "task": "Tehtävä",
   "doneText": "{0} {1} :stä on valmis",
   "confirmRemoveCalendarRemove": "Kyllä",
   "noEnd": "Syötä päättymispäivä",
   "map": "Kartta",
   "invalidTimePeriod": "Lopetusajan pitää olla aloitusajan jälkeen"
};
sv.i18n.portlet.social.tasks.tasks.no={
   "owner": "Eier",
   "participants": "Deltager",
   "more": "Les mer",
   "noTitle": "Angi en tittel",
   "confirmRemoveMessage": "Er du sikker på at du vil fjerne opplysningen?",
   "noOwner": "Angi en eier",
   "confirmRemoveCalendarSaveInCalendar": "Nei, lagre i kalenderen",
   "invalidAllDayTimePeriod": "Sluttdato må være samme eller etter startdato",
   "filter": "Vis",
   "noStart": "Angi en startdato",
   "confirmRemoveCalendarMessage": "Vil du også fjerne opplysningen fra kalenderen?",
   "isDone": "er klar",
   "confirmRemoveCalendarTitle": "Fjern",
   "confirmRemoveTitle": "Fjern",
   "task": "Opplysning",
   "doneText": "{0} av {1} er utført",
   "confirmRemoveCalendarRemove": "Ja",
   "noEnd": "Angi en sluttdato",
   "map": "Kart",
   "invalidTimePeriod": "Sluttid må være etter starttid"
};
sv.i18n.portlet.social.tasks.tasks.sv={
   "owner": "Ägare",
   "participants": "Deltagare",
   "more": "Läs mer",
   "noTitle": "Ange en titel",
   "confirmRemoveMessage": "Är du säker på att du vill ta bort uppgiften?",
   "noOwner": "Ange en ägare",
   "confirmRemoveCalendarSaveInCalendar": "Nej, spara i kalendern",
   "invalidAllDayTimePeriod": "Slutdatum måste vara samma eller efter startdatum",
   "filter": "Visa",
   "noStart": "Ange ett startdatum",
   "confirmRemoveCalendarMessage": "Vill du även ta bort uppgiften från kalendern?",
   "isDone": "är klara",
   "confirmRemoveCalendarTitle": "Ta bort",
   "confirmRemoveTitle": "Ta bort",
   "task": "Uppgift",
   "doneText": "{0} av {1} är utförda",
   "confirmRemoveCalendarRemove": "Ja",
   "noEnd": "Ange ett slutdatum",
   "map": "Karta",
   "invalidTimePeriod": "Sluttid måste vara efter starttid"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.timeline=sv.i18n.portlet.social.timeline || {};
sv.i18n.portlet.social.timeline.timeline=sv.i18n.portlet.social.timeline.timeline || {};
sv.i18n.portlet.social.timeline.timeline.de={};
sv.i18n.portlet.social.timeline.timeline.en={
   "loadEntries": "Show more posts",
   "successBookmarkTitle": "Bookmark added",
   "maliciousFileTypeTitle": "It is not allowed to upload files of the following types: htm, html and svg.",
   "expand": "Expand",
   "comments": "Comments",
   "activities": "Activities",
   "removeCommentTitle": "Delete comment",
   "removeAttachment": "Remove attached file",
   "removeBookmarkTitle": "Delete bookmark",
   "addComment": "Comment",
   "removeGroupBookmarkTitle": "Delete bookmark from group",
   "commentToLongHeading": "Too long",
   "removeReplyBody": "Are you sure that you want to delete the reply?",
   "editCommentTitle": "Edit comment",
   "removeReplyTitle": "Delete reply",
   "sharedMessageContentToPersonalTimeline": "The post is now shared on your timeline",
   "animals": "Animals & Nature",
   "editEntryTitle": "Edit post",
   "save": "Save",
   "removeEntryBody": "Are you sure that you want to delete the post?",
   "hideEntryBody": "Are you sure that you want to hide the post?",
   "showMore": "Show more",
   "collapse": "Collapse",
   "commentToLongBody": "The comment you where trying to post was too long.",
   "sharedPageContentToGroupTimeline": "The page is now shared to group '{0}'",
   "addGroupBookmarkTitle": "Add group bookmark",
   "shareComment": "Add comment",
   "removeCommentBody": "Are you sure you want to delete the comment?",
   "shareEntry": "Share post",
   "tooLargeFileMessage": "The file was to big to upload",
   "sharedPageContentToPersonalTimeline": "The page is now shared to your timeline",
   "removeEntryTitle": "Delete post",
   "shareMessageContentTitle": "Share in",
   "sharedMessageContentToGroupTimeline": "The post is now shared to group '{0}'",
   "travel": "Travel & Places",
   "share": "Share",
   "noMoreEntries": "There are no more posts",
   "inactiveGroupMessage": "Cannot interact with an inactive group",
   "showLess": "Show less",
   "food": "Food & Drinks",
   "replyLabel": "Reply",
   "smileys": "Smileys & People",
   "addEmoji": "Add emoji",
   "objects": "Objects",
   "successRemovedBookmarkTitle": "Bookmark deleted",
   "hideEntryTitle": "Hide post",
   "sharePageFail": "Failed to share page",
   "symbols": "Symbols",
   "maliciousFileTypeMessage": "File type not allowed",
   "sharedMessageTitle": "Shared post",
   "sharedPageTitle": "Shared page",
   "editReplyTitle": "Edit reply",
   "search": "Search",
   "shareMessageContentToPersonalTimeline": "Your own timeline",
   "recents": "Recent",
   "successBookmarkMessage": "Bookmark added",
   "addBookmarkTitle": "Add bookmark"
};
sv.i18n.portlet.social.timeline.timeline.fi={
   "tooLargeFileMessage": "Tiedosto, jota yritit ladata, oli liian suuri.",
   "expand": "Suurenna",
   "collapse": "Pienennä",
   "hideEntryBody": "Oletko varma, että haluat piilottaa merkinnän?",
   "removeEntryBody": "Oletko varma, että haluat poistaa merkinnän?",
   "showLess": "Näytä vähemmän",
   "removeAttachment": "Poista liitetty tiedosto",
   "comments": "Kommentteja",
   "addComment": "Kommentoi",
   "shareEntry": "Jaa merkintä",
   "noMoreEntries": "Ei enempää merkintöjä",
   "removeCommentBody": "Oletko varma, että haluat poistaa kommentin?",
   "removeCommentTitle": "Poista kommentti",
   "sharedMessageContentToPersonalTimeline": "Merkintä on jaettu seinälläsi",
   "commentToLongHeading": "Liian pitkä",
   "removeEntryTitle": "Poista merkintä",
   "sharedMessageTitle": "Jaettu viesti",
   "share": "Jaa",
   "shareComment": "Jaa kommentti",
   "inactiveGroupMessage": "Sisällön muokkaaminen ei ole mahdollista, ryhmä ei ole aktiivinen",
   "commentToLongBody": "Kommentti, jota yritit lähettää, on liian pitkä.",
   "showMore": "Näytä lisää",
   "hideEntryTitle": "Piilota merkintä"
};
sv.i18n.portlet.social.timeline.timeline.no={
   "loadEntries": "Vis flere innlegg",
   "successBookmarkTitle": "Bokmerke lagt til",
   "expand": "Utvid",
   "comments": "Kommentarer",
   "removeCommentTitle": "Fjern kommentar",
   "removeAttachment": "Fjern vedlagt fil",
   "removeBookmarkTitle": "Fjern bokmerke",
   "addComment": "Kommenter",
   "removeGroupBookmarkTitle": "Fjern bokmerke fra gruppen",
   "commentToLongHeading": "For lang",
   "removeReplyBody": "Er du sikker på at du vil fjerne svaret?",
   "editCommentTitle": "Rediger kommentar",
   "removeReplyTitle": "Fjern svar",
   "sharedMessageContentToPersonalTimeline": "Innlegget er nå delt i din flyt",
   "editEntryTitle": "Rediger innlegg",
   "save": "Lagre",
   "removeEntryBody": "Er du sikker på at du vil fjerne innlegget?",
   "hideEntryBody": "Er du sikker på at du vil skjule innlegget?",
   "showMore": "Vis mer",
   "collapse": "Minimer",
   "commentToLongBody": "Kommentaren du forsøkte å sende, var for lang.",
   "sharedPageContentToGroupTimeline": "Siden er nå delt i gruppen '{0}'",
   "addGroupBookmarkTitle": "Legg til gruppebokmerke",
   "shareComment": "Legg til kommentar",
   "removeCommentBody": "Er du sikker på at du vil fjerne kommentaren?",
   "shareEntry": "Del innlegg",
   "tooLargeFileMessage": "Filen du forsøkte å laste opp, var for stor.",
   "sharedPageContentToPersonalTimeline": "Siden er nå delt i din flyt",
   "removeEntryTitle": "Fjern innlegg",
   "shareMessageContentTitle": "Del i",
   "sharedMessageContentToGroupTimeline": "Innlegget er nå delt i gruppen '{0}'",
   "share": "Del",
   "inactiveGroupMessage": "Ikke tillatt å modifisere innhold i en inaktiv gruppe",
   "noMoreEntries": "Finnes ingen flere innlegg",
   "showLess": "Vis mindre",
   "successRemovedBookmarkTitle": "Bokmerke fjernet",
   "hideEntryTitle": "Skjul innlegg",
   "sharePageFail": "Kunne ikke dele side",
   "sharedMessageTitle": "Delt innlegg",
   "editReplyTitle": "Rediger svar",
   "sharedPageTitle": "Delt side",
   "shareMessageContentToPersonalTimeline": "Din egen flyt",
   "successBookmarkMessage": "Bokmerke lagt til",
   "addBookmarkTitle": "Legg til bokmerke"
};
sv.i18n.portlet.social.timeline.timeline.sv={
   "loadEntries": "Visa fler inlägg",
   "successBookmarkTitle": "Bokmärke tillagt",
   "maliciousFileTypeTitle": "Otillåten filtyp",
   "expand": "Expandera",
   "comments": "Kommentarer",
   "activities": "Aktiviteter",
   "removeCommentTitle": "Ta bort kommentar",
   "removeAttachment": "Ta bort bifogad fil",
   "removeBookmarkTitle": "Ta bort bokmärke",
   "addComment": "Kommentera",
   "removeGroupBookmarkTitle": "Ta bort bokmärke från gruppen",
   "commentToLongHeading": "För lång",
   "removeReplyBody": "Är du säker på att du vill ta bort svaret?",
   "editCommentTitle": "Redigera kommentar",
   "removeReplyTitle": "Ta bort svar",
   "sharedMessageContentToPersonalTimeline": "Inlägget är nu delat i ditt flöde",
   "animals": "Djur & Natur",
   "editEntryTitle": "Redigera inlägg",
   "save": "Spara",
   "removeEntryBody": "Är du säker på att du vill ta bort inlägget?",
   "hideEntryBody": "Är du säker på att du vill dölja inlägget?",
   "showMore": "Visa mer",
   "collapse": "Minimera",
   "commentToLongBody": "Kommentaren du försökte skicka var för lång.",
   "sharedPageContentToGroupTimeline": "Sidan är nu delad i gruppen '{0}'",
   "addGroupBookmarkTitle": "Lägg till som bokmärke i gruppen",
   "shareComment": "Lägg till kommentar",
   "removeCommentBody": "Är du säker på att du vill ta bort kommentaren?",
   "shareEntry": "Dela inlägg",
   "tooLargeFileMessage": "Filen som du försökte ladda upp var för stor.",
   "sharedPageContentToPersonalTimeline": "Sidan är nu delad i ditt flöde",
   "removeEntryTitle": "Ta bort inlägg",
   "shareMessageContentTitle": "Dela i",
   "sharedMessageContentToGroupTimeline": "Inlägget är nu delat i gruppen '{0}'",
   "travel": "Resor & Platser",
   "share": "Dela",
   "noMoreEntries": "Finns inga fler inlägg",
   "inactiveGroupMessage": "Inte tillåtet att modifiera innehåll i en inaktiv grupp",
   "showLess": "Visa mindre",
   "food": "Mat & Dryck",
   "replyLabel": "Svara",
   "smileys": "Smileys & Människor",
   "addEmoji": "Lägg till emoji",
   "objects": "Föremål",
   "successRemovedBookmarkTitle": "Bokmärke borttaget",
   "hideEntryTitle": "Dölj inlägg",
   "sharePageFail": "Misslyckades att dela sida",
   "symbols": "Symboler",
   "maliciousFileTypeMessage": "Det är inte tillåtet att ladda upp filer av typerna: htm, html och svg.",
   "sharedMessageTitle": "Delat inlägg",
   "sharedPageTitle": "Delad sida",
   "editReplyTitle": "Redigera svar",
   "search": "Sök",
   "shareMessageContentToPersonalTimeline": "Ditt eget flöde",
   "recents": "Nyligen använda",
   "successBookmarkMessage": "Bokmärke tillagt",
   "addBookmarkTitle": "Lägg till som bokmärke"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.usercontactsall=sv.i18n.portlet.social.usercontactsall || {};
sv.i18n.portlet.social.usercontactsall.usercontactsall=sv.i18n.portlet.social.usercontactsall.usercontactsall || {};
sv.i18n.portlet.social.usercontactsall.usercontactsall.de={};
sv.i18n.portlet.social.usercontactsall.usercontactsall.en={"successMessage": "{0} has been deleted as a contact."};
sv.i18n.portlet.social.usercontactsall.usercontactsall.fi={"successMessage": "{0} on poistettu kontakteista."};
sv.i18n.portlet.social.usercontactsall.usercontactsall.no={"successMessage": "{0} har blitt fjernet som kontakt."};
sv.i18n.portlet.social.usercontactsall.usercontactsall.sv={"successMessage": "{0} har tagits bort som kontakt."};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.userfields=sv.i18n.portlet.social.userfields || {};
sv.i18n.portlet.social.userfields.userfields=sv.i18n.portlet.social.userfields.userfields || {};
sv.i18n.portlet.social.userfields.userfields.de={"dialogTitle": "Profileinstellungen"};
sv.i18n.portlet.social.userfields.userfields.en={
   "errorTitle": "Communication error",
   "dialogTitle": "Profile settings",
   "wrong_password": "Wrong password.",
   "password_field_title": "Password",
   "password_field_description": "To save the values in the directory service, you must enter your password",
   "field_not_writeable": "Field not editable. Contact the administrator.",
   "errorMsg": "Failed to update the information",
   "server_error": "Failed to update the information. An unexpected error occurred.",
   "directory_error": "Failed to update the information. This might happen if you do not have permission if SiteVision failed to connect to the directory server."
};
sv.i18n.portlet.social.userfields.userfields.fi={
   "errorTitle": "Yhteysvirhe",
   "dialogTitle": "Profiiliasetukset",
   "wrong_password": "Annoit väärän salasanan.",
   "password_field_title": "Salasana",
   "password_field_description": "Tallentaaksesi tiedot käyttäjähakemistoon, sinun on annettava salasana.",
   "field_not_writeable": "Kenttään ei voi kirjoittaa. Ota yhteys ylläpitoon.",
   "errorMsg": "Päivitys epäonnistui",
   "server_error": "Päivitys epäonnistui. Tapahtui odottamaton virhe.",
   "directory_error": "Päivitys epäonnistui. Oikeutesi eivät ehkä riitä tähän toimintoon merkintöjesi muuttamiseen tai yhteyttä käyttäjähakemistoon ei saatu."
};
sv.i18n.portlet.social.userfields.userfields.fr={"dialogTitle": "Paramètres du profil"};
sv.i18n.portlet.social.userfields.userfields.nl={"dialogTitle": "Profielinstellingen"};
sv.i18n.portlet.social.userfields.userfields.no={
   "errorTitle": "Kommunikasjonsfeil",
   "dialogTitle": "Profilinnstillinger",
   "wrong_password": "Feil passord angitt.",
   "password_field_title": "Passord",
   "password_field_description": "Du må angi passordet ditt for å kunne lagre verdiene i katalogtjenesten",
   "field_not_writeable": "Feltet er ikke skrivbart. Kontakt administrator.",
   "errorMsg": "Mislyktes med å oppdatere informasjonen",
   "server_error": "Kunne ikke oppdatere informasjonen. Det oppsto en uventet feil.",
   "directory_error": "Kunne ikke oppdatere informasjonen. Dette kan for eksempel skyldes at du mangler rettigheter til å endre på opplysningene eller at du ikke har tilgang til katalogtjenesten."
};
sv.i18n.portlet.social.userfields.userfields.sv={
   "errorTitle": "Kommunikationsfel",
   "dialogTitle": "Profilinställningar",
   "wrong_password": "Felaktigt lösenord angivet.",
   "password_field_title": "Lösenord",
   "password_field_description": "För att kunna spara värdena i katalogtjänsten så måste du ange ditt lösenord",
   "field_not_writeable": "Fältet är inte skrivbart. Kontakta administratören.",
   "errorMsg": "Misslyckades med att uppdatera informationen",
   "server_error": "Misslyckades med att uppdatera informationen. Ett oväntat fel uppstod.",
   "directory_error": "Misslyckades med att uppdatera informationen. Detta kan exempelvis bero på att du saknar rättighet att ändra på dina uppgifter eller att det inte går att anropa katalogtjänsten."
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.usergroupsall=sv.i18n.portlet.social.usergroupsall || {};
sv.i18n.portlet.social.usergroupsall.usergroupsall=sv.i18n.portlet.social.usergroupsall.usergroupsall || {};
sv.i18n.portlet.social.usergroupsall.usergroupsall.de={"groupName": "Gruppenname"};
sv.i18n.portlet.social.usergroupsall.usergroupsall.en={
   "leftGroupHeading": "Left group",
   "leftGroupMessage": "You are no longer a member of the group '{0}'.",
   "removeGroupMessage": "You are the last administrator in the group '{0}' and if you leave the group it will be unpublished. Are you sure you want to continue?",
   "removedGroupHeading": "Group removed",
   "removedGroupMessage": "Group '{0}' has been unpublished.",
   "createGroupLabel": "Group name:",
   "createGroupTitle": "Create new group",
   "createdGroupMessage": "Created the group '{0}'.",
   "createdGroupHeading": "Group created",
   "removeGroupTitle": "Delete group",
   "groupName": "Group name"
};
sv.i18n.portlet.social.usergroupsall.usergroupsall.fi={
   "leftGroupHeading": "Piilotettu ryhmä",
   "leftGroupMessage": "Et ole enää jäsen ryhmässä '{0}'.",
   "removeGroupMessage": "Olet viimeinen moderaattori ryhmässä '{0}' ja jos lähdet ryhmästä, se poistetaan. Oletko varma, että haluat jatkaa?",
   "removedGroupHeading": "Ryhmä poistettu",
   "removedGroupMessage": "Ryhmä '{0}' on piilotettu.",
   "createGroupLabel": "Ryhmän nimi:",
   "createGroupTitle": "Luo uusi ryhmä",
   "createdGroupMessage": "Ryhmä '{0}' on nyt luotu.",
   "createdGroupHeading": "Luo ryhmä",
   "removeGroupTitle": "Poista ryhmä",
   "groupName": "Ryhmän nimi"
};
sv.i18n.portlet.social.usergroupsall.usergroupsall.fr={"groupName": "Nom du groupe"};
sv.i18n.portlet.social.usergroupsall.usergroupsall.no={
   "leftGroupHeading": "Forlot gruppe",
   "leftGroupMessage": "Du er ikke lenger medlem av gruppen '{0}'.",
   "removeGroupMessage": "Du er den siste administratoren i gruppen '{0}', og hvis du forlater gruppen, vil den bli avpublisert. Er du sikker på at du vil fortsette?",
   "removedGroupHeading": "Gruppe fjernet",
   "removedGroupMessage": "Gruppen '{0}' er avpublisert.",
   "createGroupLabel": "Gruppenavn:",
   "createGroupTitle": "Opprett ny gruppe",
   "createdGroupMessage": "Opprettet gruppen \"{0}\".",
   "createdGroupHeading": "Gruppe opprettet",
   "removeGroupTitle": "Fjern gruppe",
   "groupName": "Gruppenavn"
};
sv.i18n.portlet.social.usergroupsall.usergroupsall.sv={
   "leftGroupHeading": "Lämnade grupp",
   "leftGroupMessage": "Du är inte längre medlem i gruppen '{0}'.",
   "removeGroupMessage": "Du är den sista administratören i gruppen '{0}' och om du lämnar gruppen så kommer den att avpubliceras. Är du säker på att du vill fortsätta?",
   "removedGroupHeading": "Grupp borttagen",
   "removedGroupMessage": "Gruppen '{0}' har avpublicerats.",
   "createGroupLabel": "Gruppnamn:",
   "createGroupTitle": "Skapa ny grupp",
   "createdGroupMessage": "Skapade gruppen '{0}'.",
   "createdGroupHeading": "Grupp skapad",
   "removeGroupTitle": "Ta bort grupp",
   "groupName": "Gruppnamn"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.util=sv.i18n.portlet.social.util || {};
sv.i18n.portlet.social.util.userinformation=sv.i18n.portlet.social.util.userinformation || {};
sv.i18n.portlet.social.util.userinformation.de={
   "message": "Nachricht",
   "addAsContact": "Als kontakt hinzufügen"
};
sv.i18n.portlet.social.util.userinformation.en={
   "message": "Message",
   "addAsContact": "Add as contact"
};
sv.i18n.portlet.social.util.userinformation.fi={
   "message": "Viesti",
   "addAsContact": "Lisää kontaktiksi"
};
sv.i18n.portlet.social.util.userinformation.fr={"addAsContact": "Ajouter comme contact"};
sv.i18n.portlet.social.util.userinformation.nl={"addAsContact": "Voeg contact toe"};
sv.i18n.portlet.social.util.userinformation.no={
   "message": "Melding",
   "addAsContact": "Legg til som kontakt"
};
sv.i18n.portlet.social.util.userinformation.sv={
   "message": "Meddelande",
   "addAsContact": "Lägg till som kontakt"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.social=sv.i18n.portlet.social || {};
sv.i18n.portlet.social.videocall=sv.i18n.portlet.social.videocall || {};
sv.i18n.portlet.social.videocall.videocall=sv.i18n.portlet.social.videocall.videocall || {};
sv.i18n.portlet.social.videocall.videocall.en={
   "callMessage": "Video call from {0}, answer?",
   "roomName": "Conference name:"
};
sv.i18n.portlet.social.videocall.videocall.no={
   "callMessage": "Videosamtale fra {0}, svare?",
   "roomName": "Konferansenavn:"
};
sv.i18n.portlet.social.videocall.videocall.sv={
   "callMessage": "Videosamtal från {0}, svara?",
   "roomName": "Konferensnamn:"
};
