/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 */
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import { getPortletResourceUri } from '../../../util/portletUtil';
import {
  Events as events,
  ErrorUtil as errorUtil,
  Ajax as ajax,
  DialogUtil as dialogUtil,
} from '@sv/util';
import {
  fixTagLinks,
  fixMentionLinks,
  inputConfig,
  i18nTimeline,
  toggleCommentsTemplate,
} from '../utils';
import { toggleLike } from '../../util/likableUtil';
import CommentsView from './Comments';
import CommentsCollection from '../collection/Comments';

const TimelineEntry = Backbone.View.extend({
  tagName: 'li',

  className: 'sv-clearfix sv-timeline-entry',

  events: {
    'click [data-fn-toggle-comments]': 'toggleComments',
    'click [data-fn-portlet-likable]': 'like',
    'click [data-fn-open-comments]': 'openComments',
    'click [data-fn-share]': 'shareEntry',
    'click [data-fn-destroy-entry]': 'showDestroyDialog',
    'click [data-fn-edit-entry]': 'startEditEntry',
    'click [data-fn-bookmark-entry]': 'toggleBookmarkEntry',
    'mousedown [data-toggle="dropdown"]': 'checkBookmarkEntry',
  },

  commentCountTemplate: _.template(
    '<i class="halflings-icon comments"></i> <%= addCommentText %> (<%= commentCount %>)'
  ),

  initialize: function () {
    this.listenTo(this.model, 'destroy', this.remove);

    this.listenTo(this.model, 'update:comments', this.updateComments);
    this.listenTo(this.model, 'update:sub:comments', this.updateSubComments);
    this.listenTo(this.model, 'update:likes', this.updateLikesCount);

    this.listenTo(this.model, 'change:message', this.handleMessageChanged);

    this.listenTo(this.model, 'change:markAsUnread', function (model, value) {
      if (!value) {
        this.$('.sv-new').removeClass('sv-new');
      }
    });
  },

  handleMessageChanged: function () {
    var commentsVisible = this.$el.find('.sv-timeline-comments').is(':visible');

    //Re-renders the entry with the updated content (any shown comments are no longer visible)
    this.render();

    //this.comments must always be reset when message is changed so they can be re-rendered by this.openComments
    this.comments = undefined;

    if (commentsVisible) {
      //Re-renders the entry's comments (any previous shown comments are visible again)
      this.openComments();
    }
  },

  updateSubComments: function (content) {
    if (this.comments) {
      var comment = this.comments.get(content.comment.commentable);
      comment && comment.trigger('update:subcomments', content);
    }
  },

  updateComments: function (content) {
    var commentsCount;
    if (this.comments) {
      this.comments.add(content.comment);
      commentsCount = this.comments.length;
    } else {
      commentsCount = content.count;
    }

    this.updateCommentsCount(commentsCount);
  },

  updateCommentsCount: function (count) {
    var counter = this.$el.find('[data-fn-comment]');
    counter.html(
      this.commentCountTemplate({
        addCommentText: i18nTimeline('addComment'),
        commentCount: count,
      })
    );
  },

  updateLikesCount: function (content) {
    if (!content) {
      return;
    }
    var $like = this.$el
      .find('[data-likable-id="' + content.likableId + '"]')
      .first();
    $like.data({
      'like-count': content.count,
      likes: content.usersThatLike,
    });
    events.trigger(events.types.updateLikables, $like);
  },

  remove: function () {
    var that = this;
    this.$el.fadeOut(function () {
      that.$el.remove();
    });
  },

  checkBookmarkEntry: function () {
    var that = this;

    $.ajax({
      type: 'GET',
      dataType: 'JSON',
      url: getPortletResourceUri(
        that.options.portletId,
        'bookmarkTimelineEntry'
      ),
      data: {
        entry: that.model.get('id'),
      },
    }).done(function (response) {
      var personal = that.$el.find(
        '[data-fn-bookmark-entry][data-type="personal"]'
      );
      if (response.bookmarked) {
        personal
          .addClass('remove-bookmark')
          .text(i18nTimeline('removeBookmarkTitle'));
      } else {
        personal
          .removeClass('remove-bookmark')
          .text(i18nTimeline('addBookmarkTitle'));
      }

      var group = that.$el.find('[data-fn-bookmark-entry][data-type="group"]');
      if (response.bookmarkedInGroup) {
        group
          .addClass('remove-bookmark')
          .text(i18nTimeline('removeGroupBookmarkTitle'));
      } else {
        group
          .removeClass('remove-bookmark')
          .text(i18nTimeline('addGroupBookmarkTitle'));
      }
    });
  },

  toggleBookmarkEntry: function (e) {
    var elem = $(e.currentTarget),
      type = $(e.currentTarget).data('type'),
      remove = elem.hasClass('remove-bookmark');

    if (remove) {
      this.removeBookmarkEntry(e, type);
    } else {
      this.addBookmarkEntry(e, type);
    }

    return false;
  },

  addBookmarkEntry: function (e, type) {
    var that = this;

    $.ajax({
      type: 'POST',
      dataType: 'JSON',
      url: getPortletResourceUri(
        that.options.portletId,
        'bookmarkTimelineEntry'
      ),
      data: {
        entry: that.model.get('id'),
        type: type,
      },
    })
      .done(function (response) {
        if (response.addedBookmark) {
          events.trigger(events.types.notifyUser, {
            type: 'success',
            heading: i18nTimeline('successBookmarkTitle'),
            transient: true,
            timer: 3,
          });
        } else if (response.message) {
          events.trigger(events.types.notifyUser, {
            type: 'error',
            heading: response.message,
            transient: true,
            timer: 3,
          });
        }
      })
      .fail(function (response) {
        errorUtil.handleAjaxFailure(response);
      });

    $(e.currentTarget)
      .closest('.dropdown')
      .find('.dropdown-toggle')
      .dropdown('toggle');

    return false;
  },

  removeBookmarkEntry: function (e, type) {
    var that = this;

    $.ajax({
      type: 'DELETE',
      dataType: 'JSON',
      url:
        getPortletResourceUri(that.options.portletId, 'bookmarkTimelineEntry') +
        '&entry=' +
        that.model.get('id') +
        '&type=' +
        type,
    })
      .done(function (response) {
        if (response.removedBookmark) {
          events.trigger(events.types.notifyUser, {
            type: 'success',
            heading: i18nTimeline('successRemovedBookmarkTitle'),
            transient: true,
            timer: 3,
          });
        } else if (response.message) {
          events.trigger(events.types.notifyUser, {
            type: 'error',
            heading: response.message,
            transient: true,
            timer: 3,
          });
        }
      })
      .fail(function (response) {
        errorUtil.handleAjaxFailure(response);
      });

    $(e.currentTarget)
      .closest('.dropdown')
      .find('.dropdown-toggle')
      .dropdown('toggle');

    return false;
  },

  showDestroyDialog: function () {
    var that = this,
      heading,
      body,
      author = this.model.get('author'),
      entryOwnerId = this.model.get('entryOwnerId');

    if (!this.options.requireActiveGroup()) {
      return false;
    }

    if (
      this.model.collection.isCompoundTimeline &&
      ((author.id !== this.options.userIdentity &&
        entryOwnerId !== this.options.userIdentity) ||
        author.postedInGroup)
    ) {
      heading = i18nTimeline('hideEntryTitle');
      body = i18nTimeline('hideEntryBody');
    } else {
      heading = i18nTimeline('removeEntryTitle');
      body = i18nTimeline('removeEntryBody');
    }

    dialogUtil.showConfirmDialog(heading, body, function (success) {
      if (success) {
        that.model.destroy({
          wait: true,
        });
      }
    });

    return false;
  },

  startEditEntry: function (e) {
    e.preventDefault();

    var author = this.model.get('author');

    if (!this.options.requireActiveGroup()) {
      return false;
    }

    if (
      this.model.collection.isCompoundTimeline &&
      author.id !== this.options.userIdentity
    ) {
      return false;
    }

    ajax
      .doGet({
        context: this,
        url:
          getPortletResourceUri(this.options.portletId, 'editEntry') +
          '&timelineOwner=' +
          this.model.collection.id +
          '&entry=' +
          this.model.get('id'),
      })
      .done(function (response) {
        this.showEditEntry(response.plainMessage, response.mentions);
      })
      .fail(function (response) {
        errorUtil.handleAjaxFailure(response);
      });
  },

  showEditEntry: function (message, mentions) {
    var that = this,
      entryId = this.model.get('id'),
      modalContent = $('<div/>');

    var $charactersLeft = $(
      '<span class="sv-float-right">' +
        (this.options.maxCharacterCount - message.length) +
        '</span>'
    );

    var $messageField = $('<textarea/>')
      .attr('id', 'editEntry')
      .addClass('sv-timeline-input sv-border-box')
      .on(
        'keyup',
        (e) => {
          var text = e.currentTarget.value;
          var charactersLeft = this.options.maxCharacterCount - text.length;
          var $saveEditButton = this.getSaveEditEntryButton();

          if (charactersLeft < 0 || text.trim() === '') {
            $charactersLeft.addClass('sv-character-limit-exceeded');
            $saveEditButton
              .addClass('disabled')
              .attr('disabled', 'disabled');
          } else {
            $charactersLeft.removeClass('sv-character-limit-exceeded');
            $saveEditButton
              .removeClass('disabled')
              .prop('disabled', false);
          }

          $charactersLeft.text(charactersLeft);
        }
      )
      .val(message);

    modalContent
      .addClass('sv-timeline-portlet')
      .append($messageField)
      .append($charactersLeft);

    if (this.options.allowEmojis) {
      var $emojiButton = $('<span/>')
        .attr({
          role: 'button',
          tabindex: '0',
          'aria-label': i18nTimeline('addEmoji'),
        })
        .addClass('sv-emoji-button')
        .html('&#128515; Emoji');

      modalContent.append($emojiButton);
    }

    var buttons = [
      {
        text: i18nTimeline('save'),
        callback: $.proxy(function () {
          ajax
            .doPut({
              url:
                getPortletResourceUri(that.options.portletId, 'timelineEntry') +
                '&timelineOwner=' +
                this.id +
                '&entry=' +
                entryId,
              data: {
                message: $messageField.triggeredInput('getRichContent'),
                entry: entryId ? entryId : that.model.get('id'),
              },
              context: this,
            })
            .done(function (updatedEntry) {
              this.model.set(updatedEntry);

              if (
                updatedEntry &&
                updatedEntry.file &&
                updatedEntry.file.isVideo
              ) {
                events.trigger(events.types.updateVideoLinks, this.$el);
              }
            })
            .fail(function (response) {
              errorUtil.handleAjaxFailure(response);
            });
        }, this),
      },
    ];

    dialogUtil.showDialog({
      title: i18nTimeline('editEntryTitle'),
      body: modalContent,
      buttons: buttons,
      shownCallback: function () {
        $messageField
          .triggeredInput(inputConfig)
          .triggeredInput('setIds', mentions)
          .elastic();

        if (!this.options.allowEmojis) {
          return;
        }

        var picker = this.options.getEmojiPicker('left-end');

        picker.on('emoji', function (emoji) {
          var field = $messageField[0],
            selectionStart = field.selectionStart,
            selectionEnd = field.selectionEnd,
            inputValue = $messageField.val(),
            startString = inputValue.substring(0, selectionStart),
            endString = inputValue.substring(selectionEnd, inputValue.length);

          $messageField.val(startString + emoji + endString);
          $messageField.trigger('focus');
        });

        $emojiButton.on('click', function () {
          picker.pickerVisible
            ? picker.hidePicker()
            : picker.showPicker($emojiButton);
        });
      }.bind(this),
    });

    return false;
  },

  getSaveEditEntryButton: function () {
    return $('.modal-footer button');
  },

  shareEntry: function () {
    if (!this.options.requireActiveGroup()) {
      return false;
    }

    var that = this,
      modalContent = $('<div/>').addClass('sv-timeline-portlet'),
      timelineWrapper = $('<div/>').addClass('sv-timline'),
      message = $('<blockquote/>')
        .addClass('sv-clearfix sv-timeline-entry')
        .css({
          margin: '1em 0',
          padding: '0',
          border: 'none',
        }),
      originalMessage;

    if (this.model.get('sharedEntry')) {
      originalMessage = this.$el.find('[data-fn-shared-entry]');
    } else {
      originalMessage = this.$el;
    }

    message
      .append(originalMessage.find('img').first().clone())
      .append(originalMessage.find('h3,h4').first().clone().css('margin', '0'))
      .append(originalMessage.find('p').first().clone());

    message
      .find('img')
      .css({
        'margin-right': '1em',
        width: '48px',
      })
      .addClass('sv-image-container');

    timelineWrapper.append(message);
    modalContent.append(timelineWrapper);

    var label = $('<label/>')
      .attr('for', 'sharecomment')
      .text(i18nTimeline('shareComment'));
    modalContent.append(label);

    var messageField = $('<textarea/>')
      .attr('id', 'sharecomment')
      .addClass('sv-timeline-input sv-border-box');
    modalContent.append(messageField);

    if (this.options.allowEmojis) {
      var $emojiButton = $('<span/>')
        .attr({
          role: 'button',
          tabindex: '0',
          'aria-label': i18nTimeline('addEmoji'),
        })
        .addClass('sv-emoji-button')
        .html('&#128515; Emoji');

      modalContent.append($emojiButton);
    }

    var shareTargetLabel = $('<label/>')
      .attr('for', 'sharetarget')
      .text(i18nTimeline('shareMessageContentTitle'));
    modalContent.append(shareTargetLabel);

    var shareTargetSelection = $(
      '<select class="input-xlarge" data-share-target name="shareTarget">'
    );
    modalContent.append(shareTargetSelection);

    var buttons = [
      {
        text: i18nTimeline('share'),
        callback: function () {
          var targetTimelineOwner = shareTargetSelection.find(':selected')[0];

          $.ajax({
            type: 'POST',
            dataType: 'JSON',
            url: getPortletResourceUri(
              that.options.portletId,
              'shareTimelineEntry'
            ),
            data: {
              timelineOwner: targetTimelineOwner.value,
              message: messageField.triggeredInput('getRichContent'),
              entry: that.model.get('sharedEntry')
                ? that.model.get('sharedEntry').id
                : that.model.get('id'),
            },
          }).done(function (sharedEntry) {
            if (that.model.collection.addSharedEntries) {
              that.model.collection.add(sharedEntry, {
                append: false,
              });
            } else {
              var sharedMessageContent = /^400\./.test(
                  targetTimelineOwner.value
                )
                  ? i18nTimeline('sharedMessageContentToPersonalTimeline')
                  : i18nTimeline(
                      'sharedMessageContentToGroupTimeline',
                      targetTimelineOwner.title
                    ),
                notifyOptions = {
                  type: 'success',
                  heading: i18nTimeline('sharedMessageTitle'),
                  message: sharedMessageContent,
                  transient: true,
                  timer: 5,
                };

              if (sharedEntry.author && sharedEntry.author.groupPageURL) {
                notifyOptions.onClick = function () {
                  location.href = sharedEntry.author.groupPageURL;
                };
              }

              events.trigger(events.types.notifyUser, notifyOptions);
            }
          });
        },
      },
    ];

    $.ajax({
      type: 'GET',
      url: getPortletResourceUri(that.options.portletId, 'memberGroups'),
      context: this,
    }).done(function (result) {
      var shareMessageContentToPersonalTimeline = i18nTimeline(
          'shareMessageContentToPersonalTimeline'
        ),
        options =
          '<option value="' +
          this.model.collection.userIdentity +
          '" title="' +
          shareMessageContentToPersonalTimeline +
          '">' +
          shareMessageContentToPersonalTimeline +
          '</option>';

      _.each(
        result,
        function (memberGroup) {
          if (this.model.collection.id !== memberGroup.id) {
            options +=
              '<option value="' +
              memberGroup.id +
              '" title="' +
              memberGroup.name +
              '">' +
              memberGroup.name +
              '</option>';
          }
        },
        this
      );

      shareTargetSelection.append(options);

      dialogUtil.showDialog({
        title: i18nTimeline('shareEntry'),
        body: modalContent,
        buttons: buttons,
        shownCallback: function () {
          messageField.triggeredInput(inputConfig);

          if (!this.options.allowEmojis) {
            return;
          }

          var picker = this.options.getEmojiPicker('left-end');

          picker.on('emoji', function (emoji) {
            var field = messageField[0],
              selectionStart = field.selectionStart,
              selectionEnd = field.selectionEnd,
              inputValue = messageField.val(),
              startString = inputValue.substring(0, selectionStart),
              endString = inputValue.substring(selectionEnd, inputValue.length);

            messageField.val(startString + emoji + endString);
            messageField.trigger('focus');
          });

          $emojiButton.on('click', function () {
            picker.pickerVisible
              ? picker.hidePicker()
              : picker.showPicker($emojiButton);
          });
        }.bind(this),
      });
    });

    return false;
  },

  like: function (e) {
    if (this.options.requireActiveGroup()) {
      return toggleLike.call(e.currentTarget);
    }

    return false;
  },

  openComments: function (e) {
    var that = this,
      entryId = this.model.get('id'),
      $commentsContainer = this.$el.find('.sv-timeline-comments'),
      $loadingElem = this.$el.find('.sv-comments-loading'),
      focusCommment = e && $(e.currentTarget).is('[data-fn-comment]'),
      loadingTimer;

    if (!this.comments) {
      this.comments = new CommentsCollection([], {
        id: entryId,
        portletId: this.options.portletId,
      });

      this.commentsView = new CommentsView({
        el: $commentsContainer,
        collection: this.comments,
        portletId: this.options.portletId,
        allowEmojis: this.options.allowEmojis,
        profilePageURL: this.options.profilePageURL,
        tagResultPageURL: this.options.tagResultPageURL,
        maxCharacterCount: this.options.maxCharacterCount,
        requireActiveGroup: this.options.requireActiveGroup,
        userIdentity: this.options.userIdentity,
        getEmojiPicker: this.options.getEmojiPicker,
      });

      this.listenTo(this.comments, 'add destroy', function () {
        that.updateCommentsCount.call(that, that.comments.length);
      });
    }

    if ($commentsContainer.is(':hidden')) {
      loadingTimer = setTimeout(function () {
        $loadingElem.show();
      }, 400);
      this.comments.fetch({
        success: function (list) {
          clearTimeout(loadingTimer);
          $loadingElem.hide();
          that.updateCommentsCount(list.length);
          $commentsContainer.fadeIn('fast', function () {
            if (focusCommment) {
              $commentsContainer.find('textarea').trigger('focus');
            }
          });
        },
      });

      this.$el.find('[data-fn-toggle-comments]').html(
        toggleCommentsTemplate({
          text: i18nTimeline('collapse'),
          direction: 'up',
        })
      );
    } else if (focusCommment) {
      $commentsContainer.find('textarea').trigger('focus');
    }
    return false;
  },

  toggleComments: function (e) {
    var $commentsContainer = this.$el.find('.sv-timeline-comments');

    e.preventDefault();

    if ($commentsContainer.is(':hidden')) {
      this.openComments(e);
    } else {
      $commentsContainer.fadeOut();
      this.$el.find('[data-fn-toggle-comments]').html(
        toggleCommentsTemplate({
          text: i18nTimeline('expand'),
          direction: 'down',
        })
      );
    }
  },

  fixLinks: function () {
    var messageContent = this.$el.find('[data-fn-message-content]');

    fixMentionLinks(messageContent, this.options.profilePageURL);
    fixTagLinks(messageContent, this.options.tagResultPageURL);
  },

  render: function () {
    this.$el.html(
      this.options.template(
        _.extend({ getSuffix: this.getSuffix }, this.model.toJSON())
      )
    );
    this.fixLinks();
    events.trigger(events.types.updateLikables, this.$el);
    events.trigger(events.types.updateRelativeDates, this.$el);
    this.$el.find('[data-fn-expand-image]').fancybox({
      padding: 5,
      href: $(this).attr('href'),
      helpers: {
        title: {
          type: 'inside',
        },
      },
      transitionIn: 'elastic',
      transitionOut: 'elastic',
    });
    return this;
  },

  getSuffix: function (name, suffix) {
    var realSuffix = '';
    var suffixLength = suffix.length;
    if (name.substring(name.length - suffixLength) !== suffix) {
      realSuffix = suffix;
    }
    return realSuffix;
  },
});

export default TimelineEntry;
