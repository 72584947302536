/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * common functionality for the group tasks and the calendar
 *
 * @see calendar.js
 * @see tasks.js
 *
 * @author micke
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';
import moment from '../../vendor/moment';

import '../../vendor/timepicker/jquery.timepicker';
import '../../vendor/outsideevents/jquery.ba-outside-events';
import '../../vendor/select2-3.4.1';
import '../../vendor/bootstrap/bootstrap-editable';

import {
  Events as events,
  KeyUtil as keyUtil,
  DialogUtil as dialogUtil,
  DateUtil as dateUtil,
  i18n as _i18n,
  ClientUtil as clientUtil,
} from '@sv/util';

let i18nCommon = function (key, args) {
    return _i18n.getText('common', key, args);
  },
  i18n;

// DIALOG VIEW -------------------------------------------------------------
export const ActivityEditView = Backbone.View.extend({
  template: function () {
    return getTemplate(this.options.$portlet, this.templateName);
  },

  memberTemplate: function () {
    return getTemplate(this.options.$portlet, this.memberTemplateName);
  },

  participantTemplate: function () {
    return getTemplate(this.options.$portlet, this.participantTemplateName);
  },

  events: {
    'click [data-fn-allday]': 'toggleTimeVisibility',
    'keyup [name="where"]': 'showMapLink',
  },

  initialize: function (options) {
    this.options = options;
    this.originalValues = {};

    var template = this.template(),
      data = $.extend(this.model.toJSON(), this.templateHelpers);
    this.$el.append(template(data));

    this.listenTo(this.model, 'invalid', this.handleErrors);

    this.ui = {
      startDate: this.$el.find('[name=startDate]'),
      startTime: this.$el.find('[data-fn-time-value-start]'),
      endDate: this.$el.find('[name=endDate]'),
      endTime: this.$el.find('[data-fn-time-value-end]'),
      allDay: this.$el.find('[name=allDay]'),
      isDone: this.$el.find('[name=isDone]'),
      owner: this.$el.find('#owner'),
      participants: this.$el.find('#participants'),
    };
  },

  render: function () {
    var that = this,
      buttons = [
        {
          text: i18nCommon('cancel'),
          callback: function () {
            $('.modal').modal('hide');
            that.restoreChanges();
          },
        },
      ];

    if (this.model.isNew()) {
      buttons.push({
        text: this.i18n('create'),
        skipDismiss: true,
        primary: true,
        callback: function () {
          that.saveChanges();
        },
      });
    } else {
      buttons.push(
        {
          text: i18nCommon('remove'),
          danger: true,
          callback: $.proxy(this.removeActivity, this),
        },
        {
          text: i18nCommon('ok'),
          skipDismiss: true,
          primary: true,
          callback: function () {
            that.saveChanges();
          },
        }
      );
    }

    // first add the view to the dom
    dialogUtil.showDialog({
      title: this.model.attributes.isTask
        ? this.i18n('task')
        : this.i18n('event'),
      body: this.$el,
      buttons: buttons,
      removeOnHide: true,
      errorTitle: i18nCommon('networkErrorTitle'),
      errorMsg: i18nCommon('networkErrorText'),
    });

    var datepickerOptions = {
      format: moment.localeData()._longDateFormat.L.toLowerCase(),
      autoclose: true,
    };

    // ... then manipulate the content
    this.ui.startDate.bootstrapDatepicker(datepickerOptions).on(
      'change',
      $.proxy(function () {
        var endDate = this.ui.endDate.val(),
          startDate = this.ui.startDate.bootstrapDatepicker('getDate');
        if (!endDate && startDate) {
          this.ui.endDate.bootstrapDatepicker('setDate', startDate);
        }
      }, this)
    );

    this.ui.endDate.bootstrapDatepicker(datepickerOptions);

    var modalTopElem = this.$el.parent().parent(),
      langOptions = {
        decimal: i18nCommon('decimal'),
        mins: i18nCommon('mins'),
        hr: i18nCommon('hr'),
        hrs: i18nCommon('hrs'),
      },
      timeFormatOption = dateUtil.moment2PhpFormat(
        moment.localeData()._longDateFormat.LT
      );

    this.ui.startTime
      .timepicker({
        timeFormat: timeFormatOption,
        lang: langOptions,
        appendTo: modalTopElem,
        scrollDefaultNow: true,
      })
      .on(
        'timeFormatError',
        $.proxy(function () {
          var now = moment();
          this.ui.startTime.val(now.format('LT'));
        }, this)
      )
      .on(
        'change',
        $.proxy(function () {
          var newTime;
          if (!this.ui.endTime.val() && this.ui.startTime.val()) {
            newTime = moment(this.ui.startTime.val(), 'LT', true);
            newTime.add(2, 'hours');
            this.ui.endTime.val(newTime.format('LT'));
          }
        }, this)
      );

    this.ui.endTime
      .timepicker({
        timeFormat: timeFormatOption,
        lang: langOptions,
        appendTo: modalTopElem,
      })
      .on(
        'timeFormatError',
        $.proxy(function () {
          var startTime = this.ui.startTime.val();
          if (startTime) {
            var momentTime = moment(startTime, 'LT', true);
            momentTime.add(2, 'hours');
            this.ui.endTime.val(momentTime.format('LT'));
          } else {
            var now = moment();
            this.ui.endTime.val(now.format('LT'));
          }
        }, this)
      );

    $.ajax({
      url:
        getPortletResourceUri(
          this.options.portletId,
          this.membersWithOwnerActionName
        ) + (this.model.isNew() ? '' : '&id=' + this.model.id),
    }).done(
      $.proxy(function (members) {
        this.ui.owner.select2({
          allowClear: true,
          data: members,
          formatResult: this.memberTemplate(),
          escapeMarkup: function (m) {
            return m;
          },
        });
      }, this)
    );

    $.ajax({
      url: getPortletResourceUri(
        this.options.portletId,
        this.membersActionName
      ),
    }).done(
      $.proxy(function (members) {
        this.ui.participants.select2({
          multiple: true,
          data: members,
          formatResult: this.memberTemplate(),
          formatSelection: this.participantTemplate(),
          escapeMarkup: function (m) {
            return m;
          },
        });
      }, this)
    );
  },

  handleErrors: function (model, errors) {
    showErrors(model, errors, this.$el, this.i18n);
  },

  templateHelpers: {
    mapUrl: function (where) {
      return _mapUrl(where);
    },

    datePickerValue: function (date) {
      var dateM = moment(date);
      if (dateM.isValid()) {
        return moment(date).format('L');
      }
      return '';
    },

    timePickerValue: function (date) {
      var dateM = moment(date);
      if (dateM.isValid()) {
        return moment(date).format('LT') === '00:00' ||
          moment(date).format('LT') === '12:00 AM'
          ? ''
          : moment(date).format('LT');
      }
      return '';
    },
  },

  showMapLink: function (e) {
    if (this.typingTimer) {
      window.clearTimeout(this.typingTimer);
    }

    this.typingTimer = window.setTimeout(
      $.proxy(function () {
        var link = _mapUrl(e.currentTarget.value);
        if (!this.$mapLink) {
          this.$mapLink = this.$el.find('[data-fn-map-link]');
          if (!this.$mapLink.length) {
            // should match map entry in calendar.vm
            var el =
                '<span class="help-block"><i class="halflings-icon icon-map-marker"></i> <a data-fn-map-link class="sv-map-link" target="_blank" href="' +
                link +
                '">' +
                this.i18n('map') +
                '</a></span>',
              $el = $(el).insertAfter(e.currentTarget);
            this.$mapLink = $el.find('[data-fn-map-link]');
          }
        }

        if (e.currentTarget.value) {
          this.$mapLink.closest('.help-block').show();
        } else {
          this.$mapLink.closest('.help-block').hide();
        }

        this.$mapLink.attr('href', link);
      }, this),
      1000
    );
  },

  toggleTimeVisibility: function () {
    this.ui.startTime.toggleClass('sv-hidden');
    this.ui.endTime.toggleClass('sv-hidden');
  },

  setAndSaveForRestore: function (name, value) {
    if (!this.originalValues[name]) {
      this.originalValues[name] = this.model.get(name);
    }
    this.model.set(name, value);
  },

  restoreChanges: function () {
    var that = this;
    $.each(this.originalValues, function (key, value) {
      that.model.set(key, value);
    });
  },

  setAndSaveDateForRestore: function (name, dateValue, timeValue) {
    if (dateValue.length) {
      var momentDate = moment(dateValue, 'L', true),
        momentTime = moment(timeValue, 'LT', true);
      momentDate.hours(momentTime.hours()).minutes(momentTime.minutes());
      this.setAndSaveForRestore(name, momentDate.valueOf());
    } else {
      this.setAndSaveForRestore(name, '');
    }
  },

  saveChanges: function () {
    var that = this;
    this.$el.find('[data-fn-standard-value]').each(function () {
      that.setAndSaveForRestore(this.name, this.value.trim());
    });

    var allDay = this.ui.allDay.prop('checked');
    this.setAndSaveForRestore('allDay', allDay);

    var startDate = this.ui.startDate.val().trim(),
      startTime = this.ui.startTime.val().trim();
    this.setAndSaveDateForRestore('start', startDate, startTime);

    var endDate = this.ui.endDate.val().trim(),
      endTime = this.ui.endTime.val().trim();
    this.setAndSaveDateForRestore('end', endDate, endTime);

    var isDone = this.ui.isDone.prop('checked');
    this.setAndSaveForRestore('isDone', isDone);

    var isCreate = this.model.isNew();
    this.model.save(null, {
      wait: true,
      success: function (model) {
        that.backingModel.set(model.attributes);
        $('.modal').modal('hide');
        // propagate changes to calendar event (see calendar-portlet.js)
        if (isCreate) {
          events.trigger(events.types.activityCreated, model.attributes);
        } else {
          events.trigger(events.types.activityUpdated, model.attributes);
        }
      },
      error: function () {
        $('.alert').show();
      },
    });
  },
});

// POPOVER VIEW ------------------------------------------------------------
export const ActivityDetailView = Backbone.View.extend({
  template: function () {
    return getTemplate(this.options.$portlet, this.templateName);
  },

  events: {
    'click [data-fn-more]': 'more',
    'click [data-fn-remove]': 'removeActivity',
    'click [data-fn-edit]': 'editActivity',
    'mouseenter [data-participants]': 'showParticipants',
    'mouseleave [data-participants]': 'hideParticipants',
  },

  initialize: function (options) {
    this.options = options;

    var template = this.template(),
      data = $.extend(this.model.toJSON(), this.templateHelpers());
    // hack to be able to use i18n in the templateHelpers
    i18n = this.options.i18n;
    this.$el.append(template(data));
    i18n = null;

    this.ui = {
      description: this.$el.find('[data-fn-description]'),
      more: this.$el.find('[data-fn-more-remainder]'),
      participants: this.$el.find('[data-participants]'),
    };
  },

  render: function () {
    var escapedTitle = $('<div/>').text(this.model.get('title')).html(),
      placement = calculatePopoverPlacement(this.options.$target);
    events.trigger(events.types.popoverCreated, this);

    this.options.$target
      .popover({
        html: true,
        title:
          '<button type="button" class="close">&times;</button>' + escapedTitle,
        placement: placement,
        content: this.el,
        animation: false,
        trigger: 'manual',
        container: this.options.popoverContainer,
      })
      .popover('show');

    var popover = this.$el.closest('.popover');

    if (this.options.jsEvent) {
      var top = this.options.jsEvent.pageY;

      if (placement === 'top') {
        top -= popover.height() + 10;
      } else if (placement === 'left' || placement === 'right') {
        top -= popover.height() / 2;
      }

      popover.css({
        top: top,
      });
    }

    popover
      .on('click', '.close', $.proxy(this.closePopover, this))
      .on('clickoutside', $.proxy(this.closePopover, this));

    $(document).on('keydown', $.proxy(this.closePopoverOnEscape, this));
    $('[data-fn-request-focus]').trigger('focus');

    events.on(
      events.types.popoverCreated,
      $.proxy(this.closePopoverWhenOtherIsCreated, this)
    );

    return this;
  },

  templateHelpers: function () {
    var helpers = {
      maxSizeText: function (text) {
        var escapedText = $('<div/>').text(text).html();
        var MAX_SIZE = 150;
        if (escapedText.length < MAX_SIZE) {
          return escapedText;
        }

        var shortened =
          '<span>' +
          escapedText.substring(0, MAX_SIZE) +
          '</span><span data-fn-more class="sv-more">&nbsp;...' +
          i18n('more') +
          ' &raquo;</span>';
        shortened +=
          '<span data-fn-more-remainder class="sv-hidden">' +
          escapedText.substring(MAX_SIZE) +
          '</span>';
        return shortened;
      },

      mapUrl: function (where) {
        return _mapUrl(where);
      },
    };
    if (this.templateHelpers2) {
      helpers = $.extend(helpers, this.templateHelpers2);
    }
    return helpers;
  },

  showParticipants: function () {
    var participants = this.model.get('participants');
    if (participants && participants.length > 0) {
      if (this.participants) {
        this.showParticipantsTooltip();
      } else {
        $.ajax({
          url:
            getPortletResourceUri(
              this.options.portletId,
              this.participantsActionName
            ) +
            '&id=' +
            this.model.id,
        }).done(
          $.proxy(function (participants) {
            this.participants = participants;
            this.showParticipantsTooltip();
          }, this)
        );
      }
    }
  },

  showParticipantsTooltip: function () {
    this.ui.participants.tooltip({
      html: true,
      title: this.participants.join('<br/>'),
    });
    this.ui.participants.tooltip('show');
  },

  hideParticipants: function () {
    this.ui.participants.tooltip('destroy');
  },

  more: function (event) {
    $(event.currentTarget).addClass('sv-hidden');
    this.ui.description
      .css('max-height', this.ui.description.height() + 20 + 'px')
      .addClass('sv-show-scroll');
    this.ui.more.removeClass('sv-hidden');
  },

  closePopover: function (e) {
    if (
      e &&
      e.type === 'clickoutside' &&
      ($(e.target).hasClass('fc-view') ||
        $(e.target).children().hasClass('fc-event-container'))
    ) {
      return false;
    }

    events.off(events.types.popoverCreated);

    $(document).off('keydown', this.closePopoverOnEscape);

    this.options.$target.removeProp('popover-open');
    this.$el
      .closest('.popover')
      .off('click', '.close', this.closePopover)
      .off('clickoutside', this.closePopover)
      .popover('destroy');
  },

  editActivity: function () {
    // get the latest data from the server
    this.model.fetch({
      success: $.proxy(function (model) {
        this.createEditView(model).render();

        if (clientUtil.isTouchDevice) {
          $(window).scrollTop(0);
        }
      }, this),
    });
    this.closePopover();
    return false;
  },

  closePopoverWhenOtherIsCreated: function (popover) {
    if (this !== popover) {
      this.closePopover();
    }
  },

  closePopoverOnEscape: function (e) {
    if (e.keyCode === keyUtil.KEY.ESC) {
      this.closePopover();
      return false;
    }
  },
});

// MODELS ------------------------------------------------------------------
export const ActivityModel = Backbone.Model.extend({
  defaults: {
    title: '',
    description: '',
    where: '',
    owner: '',
    participants: '',
    start: '',
    end: '',
  },

  initialize: function (model, options) {
    this.portletId = options.portletId;
  },

  validate: function (attrs) {
    var errors = [];
    if (!attrs.title) {
      errors.push({
        field: 'title',
        message: 'noTitle',
      });
    }

    if (!attrs.owner) {
      errors.push({
        field: 'owner',
        message: 'noOwner',
      });
    }

    if (!this.allowEmptyStart && !attrs.start) {
      errors.push({
        field: 'start',
        message: 'noStart',
      });
    } else if (attrs.allDay || attrs.end) {
      if (!attrs.start) {
        errors.push({
          field: 'start',
          message: 'noStart',
        });
      }
    }

    //start after end?
    if (attrs.start && attrs.end) {
      var momentStart = moment(attrs.start),
        momentEnd = moment(attrs.end);
      if (
        !(attrs.allDay && momentEnd.isSame(momentStart)) &&
        !momentEnd.isAfter(momentStart)
      ) {
        errors.push({
          field: 'end',
          message: attrs.allDay
            ? 'invalidAllDayTimePeriod'
            : 'invalidTimePeriod',
        });
      }
    }

    //if not allDay check that end is set.
    if (!attrs.allDay && attrs.start) {
      if (!attrs.end) {
        errors.push({
          field: 'end',
          message: 'noEnd',
        });
      }
    }

    return errors.length ? errors : undefined;
  },
});

// HELPERS -----------------------------------------------------------------
export const showErrors = function (model, errors, $el, i18nMessage) {
  var i = 0,
    l = errors.length,
    error;

  $el
    .find('.control-group')
    .removeClass('error')
    .find('.help-block.error')
    .remove();

  for (; i < l; i++) {
    error = errors[i];
    $el
      .find('[name="' + error.field + '"]')
      .closest('.control-group')
      .addClass('error')
      .find('.controls')
      .append(
        '<span class="help-block error">' +
          i18nMessage(error.message) +
          '</span>'
      );
  }
};

export const calculatePopoverPlacement = function ($target) {
  if (clientUtil.isTouchDevice) {
    return 'bottom';
  }

  var offsetTop = $target.offset().top - $(window).scrollTop(), // size from browser top to target
    offsetBottom = window.innerHeight - offsetTop, // size from browser bottom to target
    offsetLeft = $target.offset().left - $(window).scrollLeft(), // size from browser left to target
    offsetRight = window.innerWidth - offsetLeft, // size from browser right to target
    popoverMaxHeight = 220;
  if (offsetBottom >= popoverMaxHeight) {
    return 'bottom';
  }
  if (offsetTop >= popoverMaxHeight) {
    return 'top';
  }
  return offsetLeft >= offsetRight ? 'left' : 'right';
};

export const stripYear = function (date, formattedDate) {
  // ok this is a bit weird, but makes it possible to rely on the language dependent format
  // defined in moment. Will work as long as all languages uses non truncated years (e.g -86 instead of 1986)
  var year = '' + moment(date).year();
  return formattedDate
    .replace(new RegExp(year, 'g'), '')
    .replace(new RegExp(' {2}', 'g'), ' ');
};

// INTERNAL ----------------------------------------------------------------
var _mapUrl = function (where) {
  return (
    'https://maps.google.se/maps?hl=' +
    sv.PageContext.userLocale +
    '&q=' +
    where +
    '&source=calendar'
  );
};

// DO ALWAYS ---------------------------------------------------------------
moment && moment.locale(sv.PageContext.userLocale);

// EXPORTS -----------------------------------------------------------------
sv.ActivityUtil = {
  showErrors: showErrors,
  calculatePopoverPlacement: calculatePopoverPlacement,
  stripYear: stripYear,
  ActivityModel: ActivityModel,
  ActivityDetailView: ActivityDetailView,
  ActivityEditView: ActivityEditView,
};
