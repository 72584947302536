/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * handles upload and cropping of profile images (i.e. user identities and groups)
 *
 * @see profileimage.vm
 * @see profileimage.css
 * @see ProfileImageAction
 * @see ProfileImageHandler
 *
 * @author micke
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import {
  getPortletResourceUri,
  getModelObjectUri,
  reloadPortletsByType,
} from '../../util/portletUtil';
import {
  Ajax as ajax,
  Events as events,
  ObjectUtil as objectUtil,
  DialogUtil as dialogUtil,
  ImageUtil as imageUtil,
  i18n as _i18n,
} from '@sv/util';

let i18nPortlet = function (key, args) {
    return _i18n.getText('portlet.social.profileimage.profileimage', key, args);
  },
  i18nCommon = function (key, args) {
    return _i18n.getText('common', key, args);
  },
  handleError = function (message) {
    events.trigger(events.types.notifyUser, {
      type: 'error',
      heading: i18nCommon('error'),
      message: message,
    });
    return false;
  };

var loadImage = function (src) {
  return $.Deferred(function (task) {
    var image = new Image();
    image.onload = function () {
      task.resolve(image);
    };
    image.onerror = function () {
      task.reject();
    };
    image.src = src;
  }).promise();
};

$('.sv-profileimage-portlet').each(function () {
  import(/* webpackChunkName: "fileUpload-plugins" */ './plugins').then(() => {
    var $this = $(this),
      profile = $this.find('div[data-profile]').data('profile'),
      defaultImageUrl = $this
        .find('div[data-default-image-url]')
        .data('default-image-url'),
      portletId = objectUtil.getObjectId($this.attr('id')),
      modelObjectUrl = getModelObjectUri(profile, 'profileImage'),
      toolsElem = $this.find('.sv-fn-tools'),
      fileUploadElems = $this.find('.sv-fn-upload'),
      mouseMenuElem = $this.find('.sv-fn-mouse-menu'),
      dropdownMenuElem = $this.find('.sv-fn-mouse-dropdown'),
      cropElem = $this.find('.sv-fn-crop'),
      buttonsElem = $this.find('.sv-fn-buttons'), // optional button (see portlet config)
      changeImageButtonSoloElem = $this.find('.sv-fn-change-btn-solo'), // optional button (see portlet config)
      imageElem = $this.find('.sv-fn-profileimage'),
      width = imageElem.data('width'),
      savingElem = $this.find('.sv-fn-saving'),
      triggerInput = $this.find('.sv-fn-change-trigger-input'),
      selectOption = $this.find('[data-select-option]');

    // handle the file upload by - jquery.fileupload.js. Add the handler to the file element (see profileimage.vm)
    // -------------------------
    fileUploadElems.fileupload({
      dataType: 'json',
      url: modelObjectUrl, // will do a POST of multi-parts (see ProfileImageHandler.uploadParts)
      replaceFileInput: true,
      formAcceptCharset: 'utf-8',
      dropZone: $this,

      // before posting the data
      submit: function (e, data) {
        // data is the file element (see profileupload.vm)
        var mimeType = data.files[0].type,
          size = data.files[0].size;

        // handle IE
        if (!mimeType) {
          var name = data.files[0].name,
            pos = name.lastIndexOf('.');

          if (pos === -1 || pos + 1 > name.length) {
            return handleError(i18nPortlet('error-type', '?'));
          }
          mimeType = 'image/' + name.substring(pos + 1).toLowerCase();
        }
        if (!size) {
          size = 1;
        }

        if (e.originalEvent.type === 'paste') {
          return false;
        }

        if (!/^image\/(jpg|png|jpeg|gif)$/.test(mimeType)) {
          return handleError(i18nPortlet('error-type', mimeType));
        }

        if (size > 64000000) {
          // ~10MB
          return handleError(i18nPortlet('error-size', 64));
        }
        toolsElem.addClass('svhidden');
        savingElem.removeClass('svhidden');
      },

      // post was successful
      done: function (e, data) {
        // result populated by ProfileImageHandler.uploadParts
        toolsElem.removeClass('svhidden');
        // handle the initial case when no image was available
        buttonsElem && buttonsElem.removeClass('svhidden');
        changeImageButtonSoloElem &&
          changeImageButtonSoloElem.addClass('svhidden');

        if (data.result.result.width < width) {
          revertProfileImage();
          return handleError(i18nPortlet('error-dimension', [width, width]));
        }
        // open crop dialog
        handleCrop(true);
      },

      // post failed (e.g. server not available)
      fail: function () {
        return handleError(i18nPortlet('error-unknown'));
      },

      // after post (failed or successful)
      always: function () {
        toolsElem.removeClass('svhidden');
        savingElem.addClass('svhidden');
      },
    });

    // propagate the click to the file element with the upload handler
    //changeImageButtonElem && changeImageButtonElem.click(triggerUpload);
    //changeImageElem.click(triggerUpload);
    //handle mouse clicks on the image (i.e. show a menu)
    var popDropdownMenu = function (e) {
      if (imageElem.attr('src') === defaultImageUrl) {
        $('.sv-fn-crop').hide();
      } else {
        $('.sv-fn-crop').show();
      }

      // extract 10 to be able to have the first elem selected. Also makes sure the menu is not disappearing in firefox
      var bounds = e.currentTarget.getBoundingClientRect();
      var posX = e.clientX - bounds.left - 10;
      var posY = e.clientY - bounds.top - 20;

      mouseMenuElem.css({ position: 'absolute', left: posX, top: posY });
      dropdownMenuElem.dropdown();
      dropdownMenuElem.trigger('click');

      return false;
    };
    imageElem.on('click', popDropdownMenu);
    imageElem.on('contextmenu', popDropdownMenu); // i.e. right click

    // handle cropping of the image by - jquery.imgareaselect.js
    var handleCrop = function (revertOnCancel) {
      // get the original, uncropped image along with the current crop configuration
      ajax.doGet({
        url: modelObjectUrl + '/' + profile,
        success: function (data) {
          openModalWindowForCropping(data.result, revertOnCancel);
        },

        error: function () {
          handleError(i18nPortlet('error-unknown'));
        },
      });
    };

    var revertProfileImage = function () {
      ajax.doDelete({
        url: modelObjectUrl + '/' + profile,

        success: function () {
          // update the profile image according to the portlet configuration (i.e. the image filter)
          ajax.doGet({
            url: getPortletResourceUri(portletId, 'profileImage'),

            success: function (data) {
              imageElem.attr('src', data.profileImageUrl);

              if (
                !data.profileImageUrl ||
                defaultImageUrl === data.profileImageUrl
              ) {
                //toolsElem.addClass("svhidden");

                // handle the initial case when no image was available
                buttonsElem && buttonsElem.addClass('svhidden');
                changeImageButtonSoloElem &&
                  changeImageButtonSoloElem.removeClass('svhidden');
              }
            },

            error: function () {
              handleError(i18nPortlet('error-unknown'));
            },
          });
        },

        error: function () {
          handleError(i18nPortlet('error-unknown'));
        },
      });
    };

    var saveCropConfiguration = function (crop) {
      ajax.doPut({
        url:
          modelObjectUrl +
          '/' +
          profile +
          '?cropX=' +
          crop.x +
          '&cropY=' +
          crop.y +
          '&cropWidth=' +
          crop.width +
          '&cropHeight=' +
          crop.height,

        success: function () {
          // update the profile image according to the portlet configuration (i.e. the image filter)
          ajax.doGet({
            url: getPortletResourceUri(portletId, 'profileImage'),

            success: function (data) {
              imageElem.attr('src', data.profileImageUrl);
              reloadPortletsByType('.sv-profileprogress-portlet');
            },

            error: function () {
              handleError(i18nPortlet('error-unknown'));
            },
          });
        },

        error: function () {
          handleError(i18nPortlet('error-unknown'));
        },
      });
    };

    var openModalWindowForCropping = function (data, revertOnCancel) {
      //the data is created by ProfileImageHandler.get
      var profileImageUrl = data.profileImageUrl,
        croppableImage = $('<img/>'),
        cropX = data.cropX,
        cropY = data.cropY,
        cropWidth = data.cropWidth,
        cropHeight = data.cropHeight,
        imageWidth = 0,
        imageHeight = 0,
        imageDimension;

      // extract width and height from the image when it is loaded and calculate the image maintaining the aspect ratio
      loadImage(profileImageUrl)
        .done(function (image) {
          imageWidth = image.width;
          imageHeight = image.height;
          imageDimension = imageUtil.getImageDimension({
            maxWidth: 530,
            minWidth: 350,
            width: imageWidth,
            maxHeight: 350,
            minHeight: 200,
            height: imageHeight,
          });

          croppableImage.attr('src', profileImageUrl);

          croppableImage.css({
            width: '100%',
            'max-width': imageDimension.width + 'px',
            height: '100%',
            'max-height': imageDimension.height + 'px',
          });
        })
        .done(function () {
          croppableImage.attr('src', profileImageUrl);
          var modalContent = $('<div/>').css({
            'text-align': 'center',
            margin: '0 auto',
            width: '100%',
            'max-width': imageDimension.width + 'px',
            height: '100%',
            'max-height': imageDimension.height + 'px',
          });

          var okClicked = false;
          modalContent.append(croppableImage);

          var buttons = [
            {
              text: i18nCommon('cancel'),
            },
            {
              text: i18nCommon('save'),
              primary: true,

              callback: function () {
                okClicked = true;
                saveCropConfiguration({
                  x: cropX,
                  y: cropY,
                  width: cropWidth,
                  height: cropHeight,
                });
              },
            },
          ];

          // we'll need to pack this in a show event to be sure that the modal window is available. Otherwise
          // imgAreaSelect will think all coordinates (i.e the cropping window) is relative the body
          var onOpen = function () {
            var x1 = cropX,
              y1 = cropY,
              x2 = cropX + cropWidth,
              y2 = cropY + cropHeight;

            // do a default crop to simplify the usage (self explaining)
            if (cropWidth === 0) {
              if (imageWidth < imageHeight) {
                x1 = 0.1 * imageWidth;
                y1 = x1;
                x2 = imageWidth - x1;
                y2 = x2;
              } else {
                y1 = 0.1 * imageHeight;
                x1 = y1;
                y2 = imageHeight - y1;
                x2 = y2;
              }

              // setting defaults
              cropX = Math.round(x1);
              cropY = Math.round(y1);
              cropWidth = Math.round(x2 - x1);
              cropHeight = Math.round(y2 - y1);
            }

            croppableImage.Jcrop({
              aspectRatio: 1,
              setSelect: [x1, y1, x2, y2],
              trueSize: [imageWidth, imageHeight],
              onSelect: function (selection) {
                if (selection.w > 0 && selection.h > 0) {
                  cropX = Math.round(selection.x);
                  cropY = Math.round(selection.y);
                  cropWidth = Math.round(selection.w);
                  cropHeight = Math.round(selection.h);
                }
              },
            });
          };

          // a messy way to remove all leftovers from the image crop tool. Do it then the modal window is closed
          var onClose = function () {
            $('.imgareaselect-handle').parent().remove();
            $('[class*="imgareaselect"]').remove();
            if (revertOnCancel && !okClicked) {
              revertProfileImage();
            }
          };

          import(/* webpackChunkName: "jCrop-plugins" */ './plugins2').then(
            () => {
              dialogUtil.showDialog({
                title: i18nPortlet('crop-title'),
                body: modalContent,
                buttons: buttons,
                removeOnHide: true,
                shownCallback: function () {
                  if ($.Jcrop) {
                    onOpen();
                  } else {
                    var pageContext = sv.PageContext,
                      prefix = '/sitevision/' + pageContext.versionPath,
                      suffix = pageContext.dev ? '.' : '-min.';

                    window.LazyLoad.css(
                      prefix + '/css/jquery.Jcrop' + suffix + 'css'
                    );
                    window.LazyLoad.js(
                      prefix + '/js/jquery.Jcrop' + suffix + 'js',
                      function () {
                        onOpen();
                      }
                    );
                  }
                },
                hiddenCallback: onClose,
              });
            }
          );
        });
    };

    cropElem.on('click', function () {
      handleCrop(false);
    });

    triggerInput.on('click', function () {
      $('#sv-fn-fileupload').trigger('click');
    });

    selectOption.on('keyup change', function (e) {
      var selectOptions = $('[data-select-option]');

      for (var i = 0; i < selectOptions.length; i++) {
        selectOptions[i].setAttribute('aria-selected', 'false');
      }

      e.currentTarget.setAttribute('aria-selected', 'true');
    });
  });
});
